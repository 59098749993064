import { i18n } from '/src/plugins/i18n'
import Vue from 'vue'
import StrapiService from '@/services/StrapiService'

class InitialisationError extends Error {
  constructor (message, originalError) {
    super(message)
    this.name = 'InitialisationError'
    this.originalError = originalError

    // Ensure the correct stack trace is captured
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = (new Error(message)).stack
    }
  }
}

class LocalesService {
  constructor () {
    this.languages = Vue.observable({
      available: []
    })
    this.loadedLocales = new Set()
  }

  async init(lang = 'en') {
    try {
      await this.refreshAvailableLocales()
      await this.setAppLanguage(lang) // TODO detect device language
    } catch (e) {
      throw new InitialisationError('Failed to fetch translations', e)
    }
  }

  async setAppLanguage(lang) {
    if (!this.loadedLocales.has(lang)) {
      console.log('LocalesService: loading messages %s', lang)
      await this.loadAppTranslations(lang)
    }
    console.log('LocalesService: setting app language to %s', lang)
    i18n.locale = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
  }

  async loadAppTranslations(locale) {
    console.log('LocalesService: loading app translations for %s', locale)

    const { data: at } = await StrapiService.getContentType('translations', {
      fields: ['key', 'value', 'locale'],
      locale
    })

    // this.updateSupportedLocales([locale])

    // Add content translations
    const messages = at.data
      .filter(tr => tr.attributes.locale === locale)
      .reduce((messages, tr) => {
        messages[tr.attributes.key] = tr.attributes.value
        return messages
      }, {})

    console.log('adding messages', locale, messages)

    i18n.setLocaleMessage(locale, messages)
    // i18n.mergeLocaleMessage(locale, messages)

    this.loadedLocales.add(locale)
    // console.log('loaded app translations for %s', locale)
  }

  async refreshAvailableLocales() {
    const { data: locales } = await StrapiService.getAvailableLocales()

    const languages = locales.map(tr => ({
      code: tr.attributes.code,
      name: tr.attributes.name
    }))

    this.updateSupportedLocales(languages)
  }
  updateSupportedLocales(locales) {
    locales
      .filter(l => !this.languages.available.some(al => al.code === l.code))
      .forEach(l => this.languages.available.push(l))
  }
}

export default new LocalesService()
