<template>
    <f-app-screen class="safe-area-top safe-area-bottom">
        <div class="flex-grow slider keen-slider pt-8 pb-4" :continuous="false" :auto="0" ref="slider">
            <tutorial-slide
                    v-for="idx in slidesCount"
                    :key="idx"
                    :index="idx"
            />
        </div>

        <div v-if="slider" class="dots">
            <button
                    v-for="(n, idx) in dotCount"
                    :key="idx"
                    @click="slider.moveToSlideRelative(idx)"
                    :class="{ dot: true, 'bg-green': current === idx, 'bg-x-900': current !== idx }"
            />
        </div>

        <div class="p-4">
            <template v-if="isLastSlide">
                <nav-button
                        :label="$t('tutorial2.button.finish.pro')"
                        :button-classes="['bg-green', 'text-backdrop']"
                        @click="goPro()"
                />
                <nav-button
                        :label="$t('tutorial2.button.finish.free')"
                        :button-classes="['text-white', 'mt-2', 'font-normal']"
                        @click="goFree()"
                        hide-icon
                />
            </template>
            <nav-button 
                    v-else
                    :label="$t('tutorial2.button.continue')"
                    :button-classes="['bg-green', 'text-backdrop']"
                    @click="next()"
            />
        </div>
    </f-app-screen>
</template>

<script>
import NavButton from '@/components/NavButton.vue'
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import Analytics, { SCREEN_TUTORIAL } from '@/services/Analytics'
import TutorialSlide from '@/modules/core/TutorialSlide.vue'

export default {
  name: 'Tutorials',
  components: {
    TutorialSlide,
    NavButton
  },
  data () {
    return {
      slidesCount: 5,
      current: 0,
      slider: null
    }
  },
  computed: {
    dotCount () {
      return this.slider?.details().size || 0
    },
    isLastSlide () {
      return this.current === this.dotCount - 1
    }
  },
  methods: {
    next () {
      this.slider.moveToSlideRelative(this.current + 1)
    },
    goFree () {
      this.$emit('finished', { 'showPro': false })
    },
    goPro () {
      this.$emit('finished', { 'showPro': true })
    }
  },
  mounted () {
    Analytics.setScreenName(SCREEN_TUTORIAL)
    this.slider = new KeenSlider(this.$refs.slider, {
      slidesPerView: 1,
      spacing: 24,
      centered: true,
      loop: false,
      initial: this.current,
      slideChanged: (s) => {
        this.current = s.details().relativeSlide
      }
    })
  },
  beforeDestroy () {
    if (this.slider) {
      this.slider.destroy()
    }
  }
}
</script>

<style scoped lang="scss">

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 10px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}
</style>
