<template>
    <div class="rounded bg-backdrop-200" :style="{ 'padding-bottom': countryCode ? 0 : '75%' }">
        <img
              v-if="countryCode"
              :src="flagIcon"
              class="rounded"
        />
    </div>
</template>

<script>
// import.meta.glob('~/svg-country-flags/svg/*.svg', {})
// import 'flag-icons/css/flag-icons.min.css'

export default {
  name: 'Flag',
  props: {
    countryCode: {
      type: String
    }
  },
  computed: {
    classes () {
      return [
        // 'fi',
        // `fi-${this.countryCode.toLowerCase()}`,
        'rounded',
        'bg-backdrop-200'
      ]
    },
    flagIcon () {
      return new URL(`/node_modules/flag-icons/flags/4x3/${this.countryCode}.svg`, import.meta.url).href
    }
  }
}
</script>
