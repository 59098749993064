import * as Sentry from '@sentry/capacitor'
import * as SentryVue from '@sentry/vue'
import { Capacitor } from '@capacitor/core'
import { version } from '@/../package.json';

const ignoreErrors = [
  'NavigationDuplicated',
  'Navigation aborted',
  'launches.appsflyer.com',
  'minTimeBetweenSessions',
  'Navigation cancelled',
  'Avoided redundant navigation'
]

export default {
  async init (Vue, router) {
    if (!Capacitor.isNativePlatform()) return

    // TODO remove when fixed
    // This is a workaround for https://github.com/getsentry/sentry-capacitor/issues/91
    const initSentryVue = (options) => SentryVue.init({...options, Vue});

    Sentry.init(
      {
        enabled: true,
        dsn: "https://08162bd5bf104e9889fa2f7945af7488@o4504803698868224.ingest.sentry.io/4504803774169088",
        integrations: [
          new SentryVue.BrowserTracing({
            routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
            tracePropagationTargets: ['localhost', 'https://api.forexhero.eu', 'https://content.forexhero.eu', /^\//],
          }),
        ],
        tracesSampleRate: 0.1,
        ignoreErrors,
        beforeSend(event, hint) {
          // Check if the exception is the one you want to filter out
          if (hint && hint.originalException) {
            const exception = hint.originalException.toString();
            if (ignoreErrors.some((ignoreError) => exception.includes(ignoreError))) {
              return null; // Returning null will prevent the event from being sent
            }
          }
          return event; // Return the event for all other cases
        },
        release: version,
        // If dist is not set, Sentry automatically assigns build number on Android (but not iOS!)
        // thus fail to find the sourcemaps for events
        // since dist is not set for sourcemaps while uploading
        dist: version,
        debug: false,
      },
      initSentryVue
    );
  }
}
