<template>
  <a href="#" @click.prevent="onClick" class="underline tracking-wide cursor-pointer">
    <slot/>
  </a>
</template>

<script>
export default {
  name: 'LinkButton',
  methods: {
    onClick (e) {
      e.preventDefault()
      this.$emit('click')
    }
  }
}
</script>

<style scoped>

</style>
