import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { i18n } from '@/plugins/i18n'
import Crashlytics from '@/services/Crashlytics'
import plugins from '@/plugins/pluigins'
import components from '@/plugins/components'
import 'animate.css/animate.css'
import Facebook from '@/services/Facebook'
import { initializeStores } from '@/services/StoresService'
import './style/main.scss'
import AppService from '@/services/AppService'
import ConfigService from '@/services/ConfigService'
import SentryService from '@/services/SentryService'

Vue.config.productionTip = false

plugins({ Vue, router })
components({ Vue })

;(async () => {
  // Initialize critical config
  await ConfigService.initializeConfig({ connectionTimout: 3000 })

  // Initialize Sentry
  await SentryService.init(Vue, router)
    .catch(e => Crashlytics.recordExceptionWithStacktrace('Sentry initialization failed', e))

  // Critical services initialization
  await Promise.all([
    Facebook.init()
      .catch(e => Crashlytics.recordExceptionWithStacktrace('FB web initialization failed', e)),
    initializeStores()
      .catch(e => Crashlytics.recordExceptionWithStacktrace('Store initialization failed', e)),
    AppService.init()
       .catch(e => Crashlytics.recordExceptionWithStacktrace('AppService initialization failed', e))
  ])

  new Vue({
    i18n,
    router,
    render: h => h(App)
  }).$mount('#app')
})().catch(e => Crashlytics.recordExceptionWithStacktrace('Vue initialization failed', e))
