<template>
    <f-app-header>
        <template v-slot:left>
            <img :src="icon"
                 :alt="$t('feedback.button.close')"
                 class="cursor-pointer"
                 @click="$emit('close')"
                 width="24"
                 height="24"/>
        </template>
        <template v-slot:default>
            <slot/>
        </template>
    </f-app-header>
</template>

<script>
export default {
  name: 'ModalHeader',
  data () {
    return {
      icon: new URL('/src/assets/icons/close.svg?url', import.meta.url).href
    }
  }
}
</script>
