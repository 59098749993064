import * as stores from '@/store'
import StorageService from '@/services/StorageService'
import { cloneDeep } from 'lodash'
import Game from '@/store/Game'
import School from '@/store/School'

async function convertProgressStore() {
  const progressData = await StorageService.get('progress')

  if (progressData) {
    if (progressData.data?.topics && progressData.data.topics.length > 0) {
      const schoolData = cloneDeep(progressData)
      delete schoolData.data.questions
      await StorageService.set(School.key, schoolData)
    }

    if (progressData.data?.questions && progressData.data.questions.length > 0) {
      const gameData = cloneDeep(progressData)
      delete gameData.data.topics
      await StorageService.set(Game.key, gameData)
    }
  }

  await StorageService.delete('progress')
}

/**
 * Initialize all stores from local storage
 * @returns {Promise<void>}
 */
export async function initializeStores () {
  // Fetch data for "progress" and split accordingly to game and school,
  // then persist as game and school store data
  await convertProgressStore()

  // Fetch data from Local Storage for each store
  const data = await Promise.all(Object.values(stores)
    .map(store => StorageService.get(store.key)))

  // Set data in each store
  Object.values(stores)
    .forEach((store, i) => {
      const d = data[i] || {}
      store.setStore(d)
    })
}
