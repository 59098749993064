import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { Capacitor } from '@capacitor/core'

export const TAG_TOPIC_ID = '{topic}'
export const TAG_TOPIC_PAGE_ID = '{page}'
export const TAG_QUIZ_QUESTION_ID = '{question}'
export const TAG_TREND_PREDICTOR_QUESTION_ID = '{question}'

export const SCREEN_MAIN_MENU = 'Main Menu'
export const SCREEN_TOP_5_BROKERS = 'Top 5 Brokers'
export const SCREEN_MARKET_HOURS = 'Market Hours'
export const SCREEN_SETTINGS = 'Settings'

export const SCREEN_FACEBOOK_LOGIN = 'Facebook Login'
export const SCREEN_APPLE_LOGIN = 'Apple Login'
export const SCREEN_RESULTS = 'Results'

export const SCREEN_TREND_PREDICTOR_START = 'Trend Predictor Start'
export const SCREEN_TREND_PREDICTOR_FINISH = 'Trend Predictor Finish'
export const SCREEN_TREND_PREDICTOR_QUESTION = 'Trend Predictor Question #' + TAG_TREND_PREDICTOR_QUESTION_ID

export const SCREEN_QUIZ_START = 'Quiz Start'
export const SCREEN_QUIZ_FINISH = 'Quiz Finish'
export const SCREEN_QUIZ_ANSWERS = 'Quiz Answers'
export const SCREEN_QUIZ_QUESTION = 'Quiz Question #' + TAG_QUIZ_QUESTION_ID

export const SCREEN_INVITE_FRIENDS = 'Invite Facebook Friends'

export const SCREEN_SCHOOL_START = 'School Start'
export const SCREEN_SCHOOL_TOPIC_LIST = 'School Topic List'
export const SCREEN_SCHOOL_TOPIC = 'School Topic ' + TAG_TOPIC_ID + ', page ' + TAG_TOPIC_PAGE_ID
export const SCREEN_SCHOOL_FINISH = 'School Finish'
export const SCREEN_SCHOOL_FINISH_FREE = 'School Finish (Free)'

export const SCREEN_TUTORIAL = 'Tutorial'

// export const SCREEN_EDIT_PROFILE = 'Edit profile'
export const SCREEN_DELETE_PROFILE = 'Delete profile'
export const SCREEN_REQUEST_PROFILE = 'Request profile'

export const SCREEN_VIEW_POLICY = 'View policy'
export const SCREEN_ACCEPT_POLICY = 'Accept policy'

export const SCREEN_RISK_DISCLOSURE = 'Risk Disclosure'

export const SCREEN_UPGRADE = 'Upgrade'

export default {
  setUserId (userId) {
    if (!Capacitor.isNativePlatform()) return

    return FirebaseAnalytics.setUserId({ userId })
      .catch(() => console.warn('Failed to set user id for analytics'));
  },

  setUserProperty ({ key, value }) {
    if (!Capacitor.isNativePlatform()) return

    return FirebaseAnalytics.setUserProperty({ name: key, value })
      .catch(() => console.warn(`Failed to set user property: ${key}`));
  },

  setScreenName (screenName) {
    if (!Capacitor.isNativePlatform()) return

    // Doesn't work without override on iOS
    const params = {
      screenName: screenName,
      nameOverride: Capacitor.getPlatform() === 'ios'
        ? 'SGContainerViewController'
        : 'MainActivity'
    }

    return FirebaseAnalytics.setScreenName(params)
      .catch(() => console.warn(`Failed to set screen name: ${screenName}`))
  },

  async getAppInstanceId () {
    if (!Capacitor.isNativePlatform()) return null

    const { instanceId } = await FirebaseAnalytics.getAppInstanceId()
    return instanceId
  },

  logEvent (name, params = {}) {
    if (!Capacitor.isNativePlatform()) return

    return FirebaseAnalytics.logEvent({ name, params })
      .catch(() => console.warn(`Failed to log event: ${name}`))
  }
}
