<template>
    <f-fullscreen-modal ref="modal">
        <f-app-screen>
            <f-modal-header @close="$refs.modal.close()"/>
            <iframe
                    src="https://forexhero.eu/terms-of-use"
                    class="w-full flex-grow "
            ></iframe>
        </f-app-screen>
    </f-fullscreen-modal>
</template>

<script>
import Analytics, { SCREEN_VIEW_POLICY } from '/src/services/Analytics'

export default {
  name: 'TermsOfUseModal',
  methods: {
    open () {
      Analytics.setScreenName(SCREEN_VIEW_POLICY)
      this.$refs.modal.open()
    }
  }
}
</script>
