<template>
    <f-app-screen class="items-center justify-center text-white">
        <p class="mb-8">{{$t('choose.language')}}</p>

        <div class="grid grid-cols-2 gap-4">
            <lang-item
                    v-for="lang in languages"
                    :key="lang.code"
                    :code="lang.code"
                    :name="lang.name"
                    :class="{ 'bg-x-900': lang.code === currentLanguage, 'rounded': true }"
                    @click="setUserLanguage"
            />
        </div>
    </f-app-screen>
</template>

<script>
import LocalesService from '@/services/LocalesService'
import LangItem from '@/components/LangItem.vue'
import { Settings } from '@/store'
import AppService from '@/services/AppService'
import { i18n } from '@/plugins/i18n'

function sortLangs (a, b, givenOrder) {
  const aIndex = givenOrder.indexOf(a);
  const bIndex = givenOrder.indexOf(b);

  // If both elements are in the order array, compare their indices
  if (aIndex !== -1 && bIndex !== -1) {
    return aIndex - bIndex;
  }

  // If only a is in the order array, a comes first
  if (aIndex !== -1) {
    return -1;
  }

  // If only b is in the order array, b comes first
  if (bIndex !== -1) {
    return 1;
  }

  // If neither a nor b are in the order array, compare them alphabetically
  return a.localeCompare(b);
}

export default {
  name: 'Language',
  components: { LangItem },
  computed: {
    languages () {
      return LocalesService.languages.available
    },
    currentLanguage () {
      return i18n.locale
    }
  },
  methods: {
    async setUserLanguage (lang) {
      await LocalesService.setAppLanguage(lang)
      await Settings.setLanguage(lang)
      AppService.registerAppEvent('language_set', lang, { language: lang })
    }
  }
}
</script>
