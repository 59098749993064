<template>
    <bottom-sheet ref="bottomSheet">
        <div class="text-center px-6 pb-12">
            <h1 class="mt-8 mb-6">{{ $t('money.disclaimer.title') }}</h1>
            <p class="text-sm mb-12 leading-normal" v-html="$t('money.disclaimer.message', [link])"></p>
            <nav-button :label="$t('money.disclaimer.ok')" tabindex="1" @click="close()"/>
        </div>
    </bottom-sheet>
</template>

<script>
import BottomSheet from '/src/components/modal/BottomSheet.vue'
import NavButton from '/src/components/NavButton.vue'

export default {
  name: 'MoneyDisclaimer',
  components: { NavButton, BottomSheet },
  computed: {
    link () {
      return `<a href="https://forexillustrated.com/r/bestbrkretroez/" target="_blank">${this.$t('money.disclaimer.message.link')}</a>`
    }
  },
  methods: {
    open () {
      return this.$refs.bottomSheet.open()
    },
    close () {
      return this.$refs.bottomSheet.close()
    }
  }
}
</script>
