<template>
    <bottom-sheet ref="bottomSheet" @hide="onHide" :z="60">
        <div class="text-center px-6 pb-12">
            <img src="/src/assets/icons/success.svg" alt="Success" class="inline-block"/>
            <h1 class="mt-8 mb-6">{{ $t('upgrade.success.title') }}</h1>
            <p class="text-sm mb-12 leading-normal">{{ $t('upgrade.success.message') }}</p>
            <nav-button label="Ok" tabindex="1" @click="close()"/>
        </div>
    </bottom-sheet>
</template>

<script>
import BottomSheet from '/src/components/modal/BottomSheet.vue'
import NavButton from '/src/components/NavButton.vue'

export default {
  name: 'SuccessSheet',
  components: { NavButton, BottomSheet },
  data () {
    return {
      resolve: null
    }
  },
  methods: {
    /**
     * Resolves on close
     * @returns {Promise<unknown>}
     */
    async open () {
      await this.$refs.bottomSheet.open()

      return new Promise(resolve => {
        this.resolve = resolve
      })
    },
    onHide () {
      if (this.resolve) {
        this.resolve()
      }
    },
    close () {
      return this.$refs.bottomSheet.close()
    }
  }
}
</script>

<style scoped>

</style>
