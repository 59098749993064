<template>
    <div>
        <f-fullscreen-modal ref="modal">
            <f-app-screen v-if="user">
                <f-modal-header @close="close()">{{ $t('profile') }}</f-modal-header>

                <f-app-body class="p-8">
                    <div class="p-4 text-center">
                        <div>{{ $t('settings.nickname') }}</div>
                        <input
                                v-model="user.nickname"
                                class="p-2 text-lg font-bold text-center focus:outline-none bg-transparent border-b-2 rounded-none"
                        />
                    </div>

                    <div class="p-4 text-center">
                        {{ $t('show.my.picture') }}
                        <div class="flex justify-center">
                            <checkbox v-model="showPic" :label="$t('yes')" name="showPic" class="m-4"/>
                            <checkbox v-model="hidePic" :label="$t('no')" name="hidePic" class="m-4"/>
                        </div>
                    </div>

                    <div class="p-4 text-center">
                        <link-button
                                class="text-primary"
                                @click="confirmDelete()"
                        >
                            {{ $t('delete.my.profile') }}
                        </link-button>
                    </div>
                </f-app-body>

                <f-app-footer class="p-4">
                    <nav-button
                            :label="$t('settings.save')"
                            @click="save()"
                            :button-classes="[ 'text-white', 'bg-green']"
                            hide-icon
                            :loading="saving"
                            :disable="saving"
                    />
                </f-app-footer>
            </f-app-screen>
        </f-fullscreen-modal>

        <delete-profile-modal ref="deleteProfile"/>
        <profile-deleted-modal ref="profileDeleted"/>
    </div>
</template>

<script>
import Checkbox from '/src/components/Checkbox.vue'
import NavButton from '/src/components/NavButton.vue'
import DeleteProfileModal from '/src/modules/settings/DeleteProfileModal.vue'
import LinkButton from '/src/components/LinkButton.vue'
import ProfileDeletedModal from '/src/modules/settings/ProfileDeletedModal.vue'
import { User } from '/src/store'
import ApiService from '/src/services/ApiService'
import AuthService from '/src/services/AuthService'
import Analytics, { SCREEN_REQUEST_PROFILE } from '/src/services/Analytics'

export default {
  name: 'ProfileModal',
  components: {
    ProfileDeletedModal,
    LinkButton,
    DeleteProfileModal,
    NavButton,
    Checkbox
  },
  data () {
    return {
      saving: false,
      user: null
    }
  },
  computed: {
    showPic: {
      get () {
        return Number(this.user.show_picture) === 1
      },
      set (val) {
        this.user.show_picture = val ? 1 : 0
      }
    },
    hidePic: {
      get () {
        return Number(this.user.show_picture) === 0
      },
      set (val) {
        this.user.show_picture = val ? 0 : 1
      }
    }
  },
  methods: {
    open () {
      Analytics.setScreenName(SCREEN_REQUEST_PROFILE)
      this.user = User.getUser()
      return this.$refs.modal.open()
    },
    close () {
      this.$refs.modal.close()
    },
    async confirmDelete () {
      await this.$refs.deleteProfile.open()
      await this.$refs.profileDeleted.open()
      this.close()
    },
    async save () {
      this.saving = true
      await ApiService.updateUser(this.user.id, this.user)
      await AuthService.refreshUser()
      this.saving = false
      this.close()
    }
  }
}
</script>
