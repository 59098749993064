import Vue from 'vue'
import StorageService from '@/services/StorageService'
import { Network } from '@capacitor/network'
import ApiService from '@/services/ApiService'
import User from '@/store/User'

const key = 'school'

const store = Vue.observable({
  meta: {
    dirty: false
  },
  data: {
    // Seen school topics
    topics: []
  }
})

function parseLegacyData (data = {}) {
  if (data.topics) {
    data.topics = data.topics.map(t => parseInt(t))
  }

  return data
}

async function persist () {
  store.meta.dirty = true

  try {
    await School.sync()
  } catch (e) {
    console.warn('School: Failed to sync school progress', e)
  } finally {
    await StorageService.set(key, store)
  }
}

const School = {
  key,

  setStore: (s = {}) => {
    if (s.data) {
      s.data = parseLegacyData(s.data)
    }
    Object.assign(store, s)
  },

  isDirty () {
    return store.meta.dirty
  },

  reset: async (d = {}) => {
    const data = Object.assign(
      { topics: [] },
      parseLegacyData(d)
    )
    School.setStore({ meta: { dirty: false }, data })
    await StorageService.set(key, store)
  },

  sync: async () => {
    const { connected } = await Network.getStatus()

    if (connected && store.meta.dirty) {
      const user = User.getUser()

      if (user) {
        await ApiService.updateSeenTopics(user.id, store.data.topics)
        store.meta.dirty = false

        // Store dirty state change
        await StorageService.set(key, store)
      }
    }
  },

  getTopics () {
    return store.data.topics
  },

  completeTopic: async (id) => {
    if (store.data.topics.includes(id)) return
    store.data.topics.push(id)

    await persist()
  }
}

export default School
