import Vue from 'vue'
import StorageService from '@/services/StorageService'
import LocalesService from '@/services/LocalesService'

const key = 'settings'

const store = Vue.observable({
  sound: true,
  vibration: true,
  language: null,

  // Dynamic parameters
  parameters: {
    seenGameUnlockedNotice: false,
    seenTutorials: false,
    seenLeverageTip: false
  }
})

async function persist () {
  await StorageService.set(key, store)
}

const Settings = {
  key,

  getStore () {
    return store
  },

  setStore (d = {}) {
    Object.assign(store, d)
  },

  async reset () {
    Object.assign(store, {
      sound: true,
      vibration: true,
      language: null,

      // Dynamic parameters
      parameters: {
        seenGameUnlockedNotice: false,
        seenTutorials: false,
        seenLeverageTip: false
      }
    })
    await StorageService.delete(key)
  },

  getSound () {
    return store.sound
  },

  getVibration () {
    return store.vibration
  },

  getLanguage () {
    return store.language
  },

  toggleSound () {
    store.sound = !store.sound
    return persist()
  },

  toggleVibration () {
    store.vibration = !store.vibration
    return persist()
  },

  async setLanguage (val) {
    await LocalesService.setAppLanguage(val)
    store.language = val
    return persist()
  },

  getParam (key, defaultValue) {
    return store.parameters[key] === undefined ? defaultValue : store.parameters[key]
  },

  setParam (key, val) {
    Vue.set(store.parameters, key, val)
    return persist()
  }
}

export default Settings
