import ApiClient from '/src/services/ApiClient'
import { cloneDeep } from 'lodash'
import { DEFAULT_BRANCH } from '@/services/DownloadService'

class ApiService {
  /**
   *
   * @param {string} facebookId
   * @param {string} email
   * @param {string} [nickname]
   * @returns {Promise<HttpResponse>}
   */
  createOrGetUser ({ facebookId, email, nickname }) {
    return ApiClient.post('/users', { facebookId, email, nickname })
  }

  /**
   *
   *
   * @param {Object} data
   * @param {string} data.email
   * @param {string} data.givenName
   * @param {string} data.familyName
   * @param {string} data.authorizationCode
   * @param {string} data.identityToken
   * @param {string} data.user
   * @returns {Promise<HttpResponse>}
   */
  createOrGetUserWithApple (data) {
    return ApiClient.post('/users/apple', data)
  }

  getUser (id) {
    return ApiClient.get(`/users/${id}`)
  }

  deleteUser (id) {
    return ApiClient.delete(`/users/${id}`)
  }

  updateUser (id, user) {
    return ApiClient.put(`/users/${id}`, user)
  }

  // eslint-disable-next-line camelcase
  getLeaderboard (params) {
    const p = cloneDeep(params)

    if (p.facebook_ids && Array.isArray(p)) {
      p.facebook_ids = p.facebook_ids.join(',')
    }

    return ApiClient.get('/users/top', { params: p })
  }

  updateSeenQuestions (userId, questions) {
    return ApiClient.put(`/users/${userId}/game_questions`, { questions })
  }

  updateSeenTopics (userId, topics) {
    return ApiClient.put(`/users/${userId}/topics`, { topics })
  }

  /**
   * Get meta data. Latest version, branch, etc.
   * @returns {Promise<{data: {version: number, api: string, updated: string, branch: string}}>}
   */
  getMeta () {
    return ApiClient.get('/meta')
      .catch((e) => {
        // fallback to latest, if api is down
        return { data: { version: 0, branch: DEFAULT_BRANCH } }
      })
  }

  updateScores (userId, scores) {
    return ApiClient.put(`/users/${userId}/scores`, scores)
  }

  /**
   * Update will also create a new instance if it doesn't exist
   *
   * @param {string} id
   * @param {Object} data
   * @returns {Promise<HttpResponse>}
   */
  updateAppInstance (id, data) {
    return ApiClient.put(`/apps/${id}`, data)
  }

  /**
   * Explicitly save appsflyer conversion data
   * @param appId
   * @param data
   * @returns {Promise<HttpResponse>}
   */
  registerAppsflyerConversion (appId, data) {
    return ApiClient.put(`/apps/${appId}/appsflyer`, data)
  }

  /**
   * Log app event
   * @param data
   * @returns {Promise<HttpResponse>}
   */
  createAppEvent (data) {
    return ApiClient.post('/events', data)
  }

  /**
   * Get remote config
   * @returns {Promise<HttpResponse>}
   */
  getRemoteConfig (options = {}) {
    return ApiClient.get('/remote-config', options)
  }

  /**
   * Request ad for display
   * @param placement
   * @param appInstanceId
   * @param options
   * @returns {Promise<HttpResponse>}
   */
  getPromo ({ placement, appInstanceId }, options = {}) {
    return ApiClient.post(`/promo/show`, { placement, 'app_instance_id': appInstanceId }, options)
  }
}

export default new ApiService()
