<template>
    <f-app-screen class="justify-end">
        <div class="py-12 px-16 flex-grow flex flex-col justify-between items-center">
            <img
                    src="@/assets/forex-hero-splash-logo.svg?url"
                    alt="Forex Hero"
                    style="width: 70%; margin-top: auto; margin-bottom: 50px;"
            />
            <img

                    src="@/assets/splash-illustration.png"
                    srcset="@/assets/splash-illustration.png 1x, @/assets/splash-illustration@2x.png 2x, @/assets/splash-illustration@3x.png 3x"
                    style="width: 100%; max-height: 50vh; object-fit: contain; margin-bottom: auto;"
            />
            <progress-bar :progress="progress" class="w-full" color="green"/>
        </div>
    </f-app-screen>
</template>

<script>
import ProgressBar from '@/components/ProgressBar.vue'

export default {
  name: 'Splash',
  components: { ProgressBar },
  props: {
    progress: {
      type: Number
    }
  }
}
</script>
