<template>
    <div
            class="cursor-pointer inline-flex items-center flex-nowrap p-4 text-sm"
            @click="$emit('click', code)"
    >
        <flag
            :country-code="countryCode"
            class="flex-shrink-0 mr-3"
            :class="flagClasses"
        />{{ name }}
    </div>
</template>

<script>
import Flag from '/src/components/Flag.vue'
import LocalesService from '/src/services/LocalesService'

const langToCountryMap = {
  en: 'gb',
  zh: 'cn',
}

export default {
  name: 'LangItem',
  components: { Flag },
  props: {
    code: {
      type: String,
      required: true
    },
    name: {
      type: String
    },
    flagClasses: {
      type: Array,
      default () {
          return ['w-8']
      }
    }
  },
  computed: {
    countryCode: function () {
      return langToCountryMap[this.code] ?? this.code
    }
  }
}
</script>
