import { CapacitorHttp } from '@capacitor/core';
import ConfigService from '@/services/ConfigService'
import { i18n } from '@/plugins/i18n'
import qs from 'qs'


/**
 *
 * @param {String} path
 * @param {String} method
 * @param {Object} params
 * @param {Object} json
 * @returns {Promise<HttpResponse>}
 */
async function request(path, method, params = null, json = null) {
  const [apiUrl, apiToken] = await Promise.all([
    ConfigService.getString('cms_api_url'),
    ConfigService.getString('cms_api_key')
  ])

  console.log('StrapiService: request', {
    path,
    locale: i18n.locale,
    apiUrl,
    apiToken
  })

  // Build url with params using qs serializer
  let url = `${apiUrl}${path}`
  if (params) {
    url += `?${qs.stringify(params)}`
  }

  const httpOptions = {
    url,
    method,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${apiToken}`
    }
  }

  if (json) {
    httpOptions.headers['Content-Type'] = 'application/json'
    httpOptions.data = JSON.stringify(json)
  }

  const res= await CapacitorHttp.request(httpOptions)

  return res.status === 200
    ? Promise.resolve(res)
    : Promise.reject(new Error(res.data?.error?.message))
}



export default {
  /**
   *
   * @param {String} contentType
   * @param {Object?} params
   * @returns {Promise<HttpResponse>}
   */
  getContentType(contentType, params = {}) {
    if (!params?.locale) {
      params = params || {};
      params.locale = i18n.locale;
      console.log('StrapiService: getContentType', i18n.locale)
    }

    return request(
      `/${contentType}`,
      'GET',
      params
    )
  },

  /**
   *
   * @param {String} contentType
   * @param {String} id
   * @param {Object} params
   * @returns {Promise<HttpResponse>}
   */
  getContentTypeEntry(contentType, id, params) {
    if (!params?.locale) {
      params = params || {};
      params.locale = i18n.locale;
    }

    return request(
      `/${contentType}/${id}`,
      'GET',
      params
    )
  },

  searchContentType(contentType, params, data) {
    if (!params?.locale) {
      params = params ?? {};
      params.locale = i18n.locale;
    }

    return request(
      `/${contentType}`,
      'POST',
      params,
      data
    )
  },

  getAvailableLocales() {
    return request(
      `/languages`,
      'GET',
      { sort: ['order:asc'] }
    ).then(({ data }) => data);
  }
};
