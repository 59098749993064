<template>
    <div class="ribbon-wrapper overflow-hidden">
        <div
                class="ribbon rounded-t bg-pinkish-500 text-white text-xs font-bold px-2 py-1 leading-none whitespace-nowrap"
                :class="classes"
        >
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
  name: 'RibbonTop',
  props: {
    horizontalLayout: {
      type: Boolean
    }
  },
  computed: {
    classes() {
      return {
        'rounded-b xs3:rounded-b-none': this.horizontalLayout
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
