import Loading from '/src/components/Loading.vue'
import Card from '/src/components/Card.vue'
import FButton from '/src/components/FButton.vue'
import ButtonGroup from '/src/components/ButtonGroup.vue'
import Modal from '/src/components/Modal.vue'
import Divider from '/src/components/Divider.vue'
import FullscreenModal from '/src/components/FullscreenModal.vue'
import AppLayout from '/src/layouts/AppLayout.vue'
import ModalHeader from '/src/components/ModalHeader.vue'
import AppBody from '/src/components/layout/AppBody.vue'
import AppHeader from '/src/components/layout/AppHeader.vue'
import AppScreen from '/src/components/layout/AppScreen.vue'
import AppFooter from '/src/components/layout/AppFooter.vue'
import FDialog from '/src/components/FDialog.vue'
import Panel from '/src/components/Panel.vue'

export default function ({ Vue }) {
  Vue.component('f-loading', Loading)
  Vue.component('f-card', Card)
  Vue.component('f-panel', Panel)
  Vue.component('f-button', FButton)
  Vue.component('f-button-group', ButtonGroup)
  Vue.component('f-modal', Modal)
  Vue.component('f-dialog', FDialog)
  Vue.component('f-divider', Divider)
  Vue.component('f-fullscreen-modal', FullscreenModal)
  Vue.component('f-app-screen', AppScreen)
  Vue.component('f-app-header', AppHeader)
  Vue.component('f-app-body', AppBody)
  Vue.component('f-app-footer', AppFooter)
  Vue.component('f-app-layout', AppLayout)
  Vue.component('f-modal-header', ModalHeader)
}
