<template>
    <a
            class="px-4 py-2 tall:py-4 bg-x-900 rounded-lg no-underline text-x-50 hover:bg-x-800 active:bg-x-800 transition-colors ring-inset ring-green relative"
            :class="classes"
            href="#"
            @click.prevent="$emit('input', true)"
    >
        <div
                class="font-medium tracking-wide whitespace-nowrap text-center"
                :class="{'xs3:mr-auto': horizontalLayout}"
                v-if="label">
            {{ label }}
        </div>

        <ribbon-top
                v-if="showRibbon && ribbon"
                class="ribbon mx-4"
                :class="ribbonClasses"
                :horizontal-layout="horizontalLayout"
        >
            {{ribbon}}
        </ribbon-top>

        <div class="xs:text-sm text-x-100" v-if="sublabel">{{ sublabel }}</div>
    </a>
</template>

<script>
import RibbonTop from '/src/components/RibbonTop.vue'
export default {
  name: 'ButtonToggle',
  components: { RibbonTop },
  props: {
    label: {
      type: String
    },
    sublabel: {
      type: String
    },
    showRibbon: {
      type: Boolean
    },
    ribbon: {
      type: String
    },
    value: {
      type: Boolean
    },
    // enables horizontal layout below xs breakpoint
    horizontalLayout: {
      type: Boolean
    }
  },
  computed: {
    classes() {
      return {
        'flex justify-between items-center xs3:mt-5 xs3:block xs3:px-2': this.horizontalLayout,
        'flex flex-col items-center px-2 mt-5': !this.horizontalLayout,// space for ribbon
        'ring-4': this.value,
      }
    },
    ribbonClasses() {
      return {
        'ribbon-offset-xs3 xs3:absolute xs3:top-0 xs3:left-1/2 xs3:mx-auto': this.horizontalLayout,//vertical layout only on big screens
        'ribbon-offset absolute top-0 left-1/2 mx-auto': !this.horizontalLayout// always vertical layout
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ribbon-offset {
  transform: translate(-50%, -100%);
}

@media (min-width: 390px) {
    .ribbon-offset-xs3 {
        transform: translate(-50%, -100%);
    }
}
</style>
