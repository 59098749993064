<template>
    <portal to="modals">
        <transition name="sheet" @after-leave="leave()" @after-enter="enter()" :duration="150">
            <div
                    v-if="visible"
                    class="overflow-hidden w-full h-full fixed top-0 left-0 right-0 bottom-0"
                    :style="{'z-index': z}"
            >
                <div class="sheet-mask" @click="close()"></div>

                <div class="sheet-container bg-backdrop rounded-t-xl pt-4 max-h-screen overflow-auto" @click.stop>
                    <div class="px-4 flex justify-end sticky top-0 right-0">
                        <a href="#" @click.prevent="close()" class="rounded-full">
                            <img src="/src/assets/icons/close-circle.svg" alt="Close"/>
                        </a>
                    </div>

                    <slot/>
                </div>
            </div>
        </transition>
    </portal>
</template>

<script>
export default {
  name: 'BottomSheet',
  props: {
    z: {
      type: Number,
      default: 50
    }
  },
  data () {
    return {
      visible: false,
      opening: null,
      closing: null
    }
  },
  methods: {
    close () {
      return new Promise(resolve => {
        this.closing = resolve
        this.visible = false
      })
    },
    open () {
      return new Promise((resolve) => {
        this.opening = resolve
        this.visible = true
      })
    },
    enter () {
      if (this.opening) this.opening()
      this.$emit('show')
    },
    leave () {
      if (this.closing) this.closing()
      this.$emit('hide')
    },
    onKeyDown (e) {
      if (this.value && e.keyCode === 27) {
        this.close()
      }
    }
  },
  mounted () {
    document.addEventListener('keydown', this.onKeyDown)
  },
  destroyed () {
    document.removeEventListener('keydown', this.onKeyDown)
  }
}
</script>

<style scoped lang="scss">
$duration: .15s;

.sheet-enter .sheet-mask,
.sheet-leave-to .sheet-mask {
  opacity: 0;
}

.sheet-enter-active .sheet-mask,
.sheet-leave-active .sheet-mask {
  transition: opacity $duration ease-in;
}

.sheet-container {
  .sheet-enter &,
  .sheet-leave-to & {
    transform: translateY(100%);
  }

  .sheet-enter-active &,
  .sheet-leave-active & {
    transition: transform $duration ease-in;
  }
}

.sheet-mask {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sheet-container {
  z-index: 51;
  width: 100%;
  position: fixed;
  bottom: 0;
  transform: translateY(50px);
  padding-bottom: 50px;
}

.sheet-enter-active .sheet-container {
  animation: bounce-in $duration;
}

.sheet-leave-active .sheet-container {
  animation: bounce-in $duration reverse;
}

@keyframes bounce-in {
  0% {
    transform: translateY(100%);
  }
  70% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50px);
  }
}
</style>
