import Vue from 'vue'
import StorageService from '@/services/StorageService'

const key = 'user'

const store = Vue.observable({
  // Auth user
  user: null
})

async function persist () {
  await StorageService.set(key, store)
}

/**
 * Store for persisting authenticated user model
 *
 * @type {{getStore(): {user: Object|null}, getUser(): Object|null, setStore({user?: Object|null}), reset(*): Promise<void>, setUser(*): Promise<void>, key: string}}
 */
const User = {
  key,

  getUser () {
    return store.user
  },

  async setUser (user) {
    store.user = user
    await persist()
    // console.log('User: setting user', user)
  },

  getStore () {
    return store
  },

  setStore ({ user = null } = {}) {
    Object.assign(store, { user })
  },

  async reset (user = null) {
    User.setStore({ user })
    await persist()
  }
}

export default User
