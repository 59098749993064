import ApiService from '@/services/ApiService'
import Vue from 'vue'

const store = Vue.observable({
  promo: null
});

export const PROMO_PLACEMENTS = {
  'SCHOOL_TOPIC': 'school_topic',
  'QUIZ_FINISH': 'quiz_finish',
  'TREND_PREDICTOR_FINISH': 'trend_predictor_finish',
}

export function getPromo() {
  return store.promo
}

export function clearPromo() {
  store.promo = null
}

/**
 * @param {string} url
 * @param {Function} callback
 */
export function setPromo({ url, callback }) {
  store.promo = { url, callback }
}

function checkTargetCondition(userValue, targetArray) {
  if (!targetArray || targetArray.length === 0) {
    return true
  } else {
    return userValue && targetArray.includes(userValue)
  }
}


export async function maybeShowPromo(placement, callback = null) {
  try {
    const { data: promo } = await ApiService.getPromo(
      { placement },
      { connectionTimout: 3000 }
    )

    // Store only the url and callback
    if (promo?.url) {
      setPromo({
        url: promo.url,
        callback
      })
    } else {
      if (callback) callback()
    }
  } catch (e) {
    console.warn('Failed to fetch promo', e)
    if (callback) callback()
  }
}
