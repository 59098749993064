import Vue from 'vue'
import VueRouter from 'vue-router'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import Analytics, { SCREEN_MAIN_MENU, SCREEN_MARKET_HOURS, SCREEN_TOP_5_BROKERS } from '@/services/Analytics'
import { ScreenOrientation, OrientationType } from '@capawesome/capacitor-screen-orientation'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/modules/core/Home.vue'),
    meta: {
      title: SCREEN_MAIN_MENU
    }
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('@/modules/game/Game.vue')
  },
  {
    path: '/quiz',
    name: 'quiz',
    component: () => import('@/modules/quiz/Quiz.vue')
  },
  {
    path: '/brokers',
    name: 'brokers',
    component: () => import('@/modules/brokers/Brokers.vue'),
    meta: {
      title: SCREEN_TOP_5_BROKERS
    }
  },
  {
    path: '/top5brokers',
    redirect: { name: 'brokers' }
  },
  {
    path: '/school',
    name: 'school',
    component: () => import('@/modules/school/School.vue'),
    redirect: { name: 'school.topics' },
    children: [
      {
        path: 'topics',
        name: 'school.topics',
        component: () => import('@/modules/school/Topics.vue')
      },
      {
        path: 'topics/:id',
        name: 'school.topic',
        props: true,
        component: () => import('@/modules/school/Topic.vue')
      }
    ]
  },
  {
    path: '/hours',
    name: 'hours',
    component: () => import('@/modules/hours/Hours.vue'),
    meta: {
      orientation: 'unlock',
      title: SCREEN_MARKET_HOURS
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes
})

if (Capacitor.isNativePlatform()) {
  App.addListener('backButton', () => {
    if (router.currentRoute.name === 'home') {
      App.exitApp()
    } else {
      router.go(-1)
    }
  })

  App.addListener('appUrlOpen', (event) => {
    const url = new URL(event.url)
    // normalize path if domain is missing. eg. forexhero://top5brokers
    const path = (url.pathname || url.hostname).replace(/^\/\//, '')
    router.push('/' + path + url.search)
  })
}

router.beforeEach((to, from, next) => {
  // Handle screen orientation based on view
  if (Capacitor.isNativePlatform()) {
    if (to.meta?.orientation === 'unlock') {
      ScreenOrientation.unlock()
    } else {
      ScreenOrientation.lock({ type: OrientationType.PORTRAIT })
    }
  }

  // Set analytics data
  if (to.meta.title) {
    // console.log('---> Router setting screen name', to.meta.title)
    Analytics.setScreenName(to.meta.title)
  }

  next()
})

export default router
