<template>
  <div class="relative p-1 bg-backdrop-150 w-12 h-6 rounded-full shadow-inner transition-colors duration-300 ease-in-out"
       :class="{ 'bg-positive': value }" @click="$emit('input', !value)">
    <input :value="value" type="checkbox" class="hidden" />
    <div class="absolute w-5 h-5 bg-white rounded-full left-0.5 top-0.5 transition-transform duration-300 ease-in-out"
         :class="{ 'transform translate-x-6': value }"></div>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
