<template>
    <div class="app-header relative bg-x-1500">
        <div
            v-if="hasCountdown"
            class="absolute z-0 left-0 top-0 bottom-0 bg-primary"
            :style="{ width: countdown + '%' }"
        ></div>

        <div class="relative text-white safe-area-left safe-area-right safe-area-top">
            <div class="h-12 p-2 z-10 relative flex flex-row justify-center items-center z-10 w-full">
                <div class="absolute left-3" v-if="$slots.left">
                    <slot name="left"/>
                </div>

                <h1 class="text-white font-title uppercase text-2xl mx-10 overflow-ellipsis whitespace-nowrap min-w-0 overflow-hidden flex flex-nowrap items-center leading-none">
                    <slot/>
                </h1>

                <div class="absolute right-3" v-if="$slots.right">
                    <slot name="right"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AppHeader',
  props: {
    countdown: {
      type: Number
    }
  },
  computed: {
    hasCountdown () {
      return typeof this.countdown === 'number'
    }
  }
}
</script>

<style scoped lang="scss">
.app-header {
  // for your app's header
  //padding-top: constant(safe-area-inset-top); // for iOS 11.0
  //padding-top: env(safe-area-inset-top); // for iOS 11.2 +
  //  &:before {
  //    content: "";
  //    display: block;
  //    width: 100%;
  //    background-color: #1C2232;
  //    height: 0;
  //    height: constant(safe-area-inset-top); // for iOS 11.0;
  //    height: env(safe-area-inset-top); // for iOS 11.2 +
  //  }
}
</style>
