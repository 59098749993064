<template>
    <f-button
            class="w-full relative rounded-lg"
            v-bind="$attrs"
            size="19px"
            @click="$emit('click', $event)"
            :class="buttonClasses"
            :padding="['py-3', 'px-5']"
    >
        <spinner
                v-if="busy"
                class="mr-2"
                size="1.2em"
        />
        <span v-html="label"></span>
        <slot name="icon">
            <svg
                    v-if="!hideIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40.7 43.32"
                    class="absolute right-4"
                    style="width: 1em; height: 1em;"
            >
                <path
                        style="fill: currentColor"
                        d="M13166.7,9556.95l-0.7.67V9558h-0.4l-20.1,20.17-2.2-2.12,18.1-18.05H13126v-3h35.4l-18.1-18.05,2.2-2.12,20.1,20.17h0.4v0.38l0.7,0.67-0.5.45Z"
                        transform="translate(-13126 -9534.84)"
                />
            </svg>
        </slot>
    </f-button>
</template>

<script>
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'NavButton',
  components: {
    Spinner
  },
  props: {
    label: {
      type: String,
      required: true
    },
    buttonClasses: {
      type: [Object, Array],
      default: function () {
        return [
          'bg-green',
          'hover:bg-green-300',
          'active:bg-green-300',
          'transition-colors',
          'text-backdrop'
        ]
      }
    },
    hideIcon: {
      type: Boolean
    },
    busy: {
      type: Boolean
    }
  }
}
</script>

<style scoped>

</style>
