import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics'
import { Settings } from '/src/store'

class HapticsService {
  impact(style = ImpactStyle.Medium) {
    if (!Settings.getVibration()) return
    Haptics.impact({ style })
  }

  notification(type = NotificationType.SUCCESS) {
    if (!Settings.getVibration()) return
    Haptics.notification({ type })
  }

  vibrate() {
    if (!Settings.getVibration()) return
    Haptics.vibrate()
  }
}

export default new HapticsService()
