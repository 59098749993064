import Vue from 'vue'
import { Capacitor } from '@capacitor/core'
import Crashlytics from '@/services/Crashlytics'
import ConfigService from '@/services/ConfigService'
import AppService from '@/services/AppService'
import Analytics from '@/services/Analytics'
import { Purchases } from "@revenuecat/purchases-capacitor";
import { Network } from '@capacitor/network'

const LIFETIME_PRODUCTS = [ 'pro_life', 'pro_lifetime' ]

// TODO rename to PACKAGE_PRO and PACKAGE_FREE
export const ACCESS_FREE = 1
export const ACCESS_PRO = 2
export const ENTITLEMENT_PRO = 'pro'

let plan = ACCESS_FREE

// check query param "plan" for debug purposes
// if (!Capacitor.isNativePlatform()) {
//   const urlParams = new URLSearchParams(window.location.search)
//   plan = urlParams.has('pro') ? ACCESS_PRO : ACCESS_FREE
// }

const state = Vue.observable({
  plan,
  product: null,
  offering: 'triple' // FIXME DEFAULT_CONFIG is asynchronously loaded so not available here
})

class PurchasesError extends Error {
  constructor (purchaseError) {
    super(purchaseError.message)

    this.code = purchaseError.code
    this.readableErrorCode = purchaseError.readableErrorCode
    if (purchaseError.underlyingErrorMessage) {
      this.underlyingErrorMessage = purchaseError.underlyingErrorMessage
    }
  }
}

/**
 * Update current plan based on customer info
 * @param customerInfo
 * @returns {void}
 */
function updatePlan (customerInfo) {
  const currentEntitlements = Object.keys(customerInfo.entitlements?.active || {})

  state.plan = currentEntitlements.includes(ENTITLEMENT_PRO)
    ? ACCESS_PRO
    : ACCESS_FREE

  // Store current product identifier. Required for detection of lifetime plan.
  if (customerInfo.entitlements.active[ENTITLEMENT_PRO]) {
    state.product = customerInfo.entitlements.active[ENTITLEMENT_PRO].productIdentifier
  }
}



export default {
  state,

  getAccessiblePackages (packageId) {
    const accessible = [ACCESS_FREE]
    const pid = packageId ?? state.plan

    if (pid !== ACCESS_FREE) {
      accessible.push(pid)
    }

    return accessible
  },

  /**
   *
   * @param {Object} content
   * @param {Number} [packageId]
   * @returns {boolean}
   */
  hasAccess (content, packageId) {
    const p = packageId ?? state.plan

    // Unmarked or free content is always accessible,
    // alternatively it must match current entitlement
    return typeof content.package_id === 'undefined' ||
      content.package_id === ACCESS_FREE ||
      content.package_id === p
  },

  /**
   * Initialize subscriptions service for the user
   *
   * @param id
   * @param email
   * @param displayName
   * @returns {Promise<void>}
   */
  async init ({ id: appUserID, email, displayName }) {
    // Skip for not native platforms
    if (!Capacitor.isNativePlatform()) return

    // Skip if configured
    const { isConfigured } = await Purchases.isConfigured()
    if (isConfigured) return

    // window.Purchases.setDebugLogsEnabled(true)

    state.offering = await ConfigService.getString('offering')
    // console.log('SubService: offering', state.offering)

    let apiKey

    if (Capacitor.getPlatform() === 'android') {
      apiKey = await ConfigService.getString('rc_public_google_key')
    } else if (Capacitor.getPlatform() === 'ios') {
      apiKey = await ConfigService.getString('rc_public_apple_key')
    }

    // Configure RevenueCat
    await Purchases.configure({
      apiKey,
      appUserID
    })

    // Get firebase app instance ID
    const firebaseAppInstanceID = await Analytics.getAppInstanceId().catch(() => null)

    // Collect device identifiers, set app instance ID and user attributes
    await Promise.all([
      Purchases.collectDeviceIdentifiers(),
      Purchases.setAttributes({ 'forexHeroAppInstanceId': AppService.getAppId() }),
      appUserID && this.setUserAttributes({ email, displayName }),
      firebaseAppInstanceID && Purchases.setFirebaseAppInstanceID({ firebaseAppInstanceID }),
      Purchases.addCustomerInfoUpdateListener(updatePlan)
    ])
  },

  async setAppsFlyerId (appsflyerID) {
    if (!Capacitor.isNativePlatform()) return

    const { isConfigured } = await Purchases.isConfigured()
    if (!isConfigured || !appsflyerID) return

    // const { isAnonymous } = await Purchases.isAnonymous()

    return Purchases.setAppsflyerID({ appsflyerID })
  },

  /**
   * Promise wrapper for getAppUserID
   * @returns {Promise<String|null>}
   */
  async getRevenueCatUserId () {
    if (!Capacitor.isNativePlatform()) return null

    const { isConfigured } = await Purchases.isConfigured()
    if (!isConfigured) return null

    const { appUserID } = await Purchases.getAppUserID()
    return appUserID
  },

  async logIn ({ id, email, displayName }) {
    if (!Capacitor.isNativePlatform()) return

    const { isConfigured } = await Purchases.isConfigured()
    if (!isConfigured) return

    try {
      const { customerInfo } = await Purchases.logIn({ appUserID: id })
      await this.setUserAttributes({ email, displayName })
      return customerInfo
    } catch (error) {
      const e = new PurchasesError(error)
      console.warn('RevenueCat login failed', e)
      Crashlytics.recordExceptionWithStacktrace('RevenueCat login failed', e)
      throw e
    }
  },

  async setUserAttributes ({ email, displayName }) {
    if (!Capacitor.isNativePlatform()) return

    const { isConfigured } = await Purchases.isConfigured()
    if (!isConfigured) return

    return Promise.all([
      email && Purchases.setEmail(email),
      displayName && Purchases.setDisplayName(displayName)
    ])
  },

  async logOut () {
    if (!Capacitor.isNativePlatform()) return

    const { isConfigured } = await Purchases.isConfigured()
    if (!isConfigured) return

    try {
      await Purchases.logOut()
      state.plan = ACCESS_FREE
    } catch (error) {
      const e = new PurchasesError(error)
      console.warn('RevenueCat logout failed', e)
      Crashlytics.recordExceptionWithStacktrace('RevenueCat logout failed', e)
      throw e
    }
  },

  async getCustomerInfo () {
    if (!Capacitor.isNativePlatform()) return

    const { isConfigured } = await Purchases.isConfigured()
    if (!isConfigured) return

    return Purchases.getCustomerInfo()
      .then(({ customerInfo }) => customerInfo)
      .catch(error => {
        const e = new PurchasesError(error)
        console.warn('Error fetching customer info', e)
        Crashlytics.recordExceptionWithStacktrace('Error fetching customer info', e)
        throw e
      })
  },

  async refreshCustomerInfo () {
    if (!Capacitor.isNativePlatform()) return

    // Skip refresh if not connected
    const { connected } = await Network.getStatus()
    if (!connected) return

    const info = await this.getCustomerInfo()
    updatePlan(info)
  },

  async getAvailablePackages () {
    if (!Capacitor.isNativePlatform()) return Promise.resolve([])

    const { isConfigured } = await Purchases.isConfigured()
    if (!isConfigured) return Promise.resolve([])

    return Purchases.getOfferings()
      .catch(error => {
        const e = new PurchasesError(error)
        console.warn('Failed getting RevenueCat offerings', e)
        Crashlytics.recordExceptionWithStacktrace('Failed getting RevenueCat offerings', e)
      })
      .then((offerings) => {
        if (offerings.all[state.offering]) {
          // Get the price and introductory period from the PurchasesProduct
          return offerings.all[state.offering].availablePackages
        } else {
          const message = `Offering ${state.offering} not found`
          console.warn(message)

          // Fallback to default offering or reject
          if (offerings.all?.default) {
            return offerings.all.default.availablePackages
          } else {
            throw new Error(message)
          }
        }
      })
  },

  async purchase (productId) {
    if (!Capacitor.isNativePlatform()) return

    const { isConfigured } = await Purchases.isConfigured()
    if (!isConfigured) return

    const packs = await this.getAvailablePackages()

    const pack = packs.find(p => p.product.identifier === productId)

    if (!pack) {
      const message = `Package ${productId} not found`
      throw new Error(message)
    }

    return Purchases.purchasePackage({ aPackage: pack })
      .then(({ productIdentifier, customerInfo }) => updatePlan(customerInfo))
      .catch((error) => {
        // Error making purchase
        const e = new PurchasesError(error.message)
        console.warn('Error making purchase', e)
        Crashlytics.recordExceptionWithStacktrace(e.message, e)
        throw e
      })
  },

  /**
   * Restores purchases for the user.
   *
   * @returns {Promise<Object>} A Promise that resolves when the restore process is complete.
   * @throws {PurchasesError} If there was an error restoring purchases.
   */
  async restore () {
    if (!Capacitor.isNativePlatform()) return

    const { isConfigured } = await Purchases.isConfigured()
    if (!isConfigured) return

    try {
      const { customerInfo } = await Purchases.restorePurchases()
      updatePlan(customerInfo)
      return customerInfo
    } catch (error) {
      const e = new PurchasesError(error)
      console.warn('Error restoring purchases', e)
      Crashlytics.recordExceptionWithStacktrace('Error restoring purchases', e)
      throw e
    }
  },

  async getManagementUrl () {
    const { managementURL } = await this.getCustomerInfo()
    return managementURL
  },

  isLifetime () {
    return state.product &&
      LIFETIME_PRODUCTS.some(key => state.product.startsWith(key))
  }
}
