<template>
    <button
            class="px-14 py-2 ease-in-out focus:outline-none rounded text-white font-bold relative overflow-hidden flex justify-center items-center bg-black"
            style="min-width: 180px"
            :class="{ 'text-opacity-10': busy}"
            :disabled="busy"
            @click="startSignInWithApple()"
    >
        <img
                src="@/assets/icons/apple-logo-white.svg?url"
                class="block w-3 h-3 mr-3"
        />
        <span><slot/></span>
        <f-loading :showing="busy" color="#FFFFFF" overlay/>
    </button>
</template>

<script>
import Analytics, { SCREEN_APPLE_LOGIN } from '@/services/Analytics'
import { signInWithApple } from '@/services/AppleSignIn'
import AuthService from '@/services/AuthService'

export default {
  name: 'FacebookButton',
  data () {
    return {
      busy: false
    }
  },
  methods: {
    async startSignInWithApple () {
      if (this.busy) return

      Analytics.setScreenName(SCREEN_APPLE_LOGIN)

      try {
        this.busy = true
        const { response } = await signInWithApple()
        await AuthService.authenticateWithApple(response)
      } catch (e) {
        console.error('Apple sign in error', e)
      } finally {
        this.busy = false
      }
    },
  }
}
</script>
