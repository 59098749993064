import { SHA256, enc } from 'crypto-js'
import User from '/src/store/User'
import AppService from '@/services/AppService'
import ConfigService from '@/services/ConfigService'
import { CapacitorHttp } from '@capacitor/core'

function timeoutPromise(ms, path) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Request timed out for ' + path + '.'))
    }, ms)
  });
}

async function request (path, options = {}) {
  const o = Object.assign({}, options)
  const timeout = await ConfigService.getNumber('api_timeout')
  const baseUrl = await ConfigService.getString('api_url')
  o.url = baseUrl + path

  // Add app id header
  const appId = AppService.getAppId()
  if (appId) {
    o.headers = o.headers || {}
    o.headers['Fh-App-Id'] = appId
  }

  if (!o.connectionTimout && timeout) {
    // FIXME: There is a bug in CapacitorHttp, where it doesn't respect the timeout
    // Using promise race to work around it
    o.connectionTimout = timeout
    // o.readTimeout = timeout
  }

  // Add auth headers
  if (User.getUser()) {
    o.headers = o.headers || {}
    const apiKey = await ConfigService.getString('api_key')
    addAuthHeaders(o.headers, apiKey, User.getUser().id)
  }

  // We always want JSON
  o.headers = o.headers || {}
  o.headers['Accept'] = 'application/json'

  // If we have data, it is JSON. Add content type and stringify
  if (options.data) {
    o.headers['Content-Type'] = 'application/json'
    o.body = JSON.stringify(options.data)
  }

  return Promise.race([
    CapacitorHttp.request(o),
    timeoutPromise(o.connectionTimout || 60000, path)// 60 seconds in case no timeout is set
  ])
}

function createToken (ts, apiKey, userId) {
  const t = [ts, apiKey, userId].join('')
  return SHA256(t).toString(enc.Hex)
}

function addAuthHeaders (headers, apiKey, userId) {
  headers.Timestamp = (Date.now() / 1000).toString()
  headers.Token = createToken(headers.Timestamp, apiKey, userId)
}

export default {
  get (path, options = {}) {
    return request(path, Object.assign({}, options, { method: 'GET' }))
  },
  put (path, data) {
    return request(path, { data, method: 'PUT' })
  },
  post (path, data, options = {}) {
    return request(path, Object.assign({}, options, { data, method: 'POST' } ))
  },
  delete (path, data) {
    return request(path, { data, method: 'DELETE' })
  }
}
