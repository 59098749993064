import { Game, Scores } from '/src/store'
import SubService, { ACCESS_PRO } from '/src/services/SubService'
import StrapiService from '@/services/StrapiService'
import Vue from 'vue'

export const GAME_LENGTH = 10

export const GAME_THRESHOLD = 200

export const GAME_INTERRUPT_THRESHOLD = 100

// Reactive cache for quiz stats
const store = Vue.observable({
  stats: null
})

const GameService = {
  addBalance(val) {
    Scores.addBalance(val)
    Scores.addProfit(val)
  },

  removeBalance(val) {
    Scores.removeBalance(val)
    Scores.addLoss(val)
  },

  addAnsweredQuestion({ question, correct }) {
    return Game.addAnsweredQuestion(question.originalId ?? question.id, correct)
  },

  // /**
  //  * @param {String|Number} [access] Options. Omit to get questions for current user plan.
  //  * @returns {Promise<*>}
  //  */
  // async getQuestions(access) {
  //   const { data: questions } = await StrapiService.getContentType('game-questions')
  //   return questions?.data.filter(q => SubService.hasAccess(q, access)) || []
  // },

  /**
   *
   * @param {Boolean} refresh
   * @returns {Promise<*>}
   */
  async getStats(refresh = false) {
    if (!refresh && store.stats) {
      return store.stats;
    }

    const { data: stats } = await StrapiService.getContentType('game-questions/stats')

    Vue.set(store, 'stats', stats);

    return store.stats;
  },

  async getGameQuestions() {
    const answered = Game.getAnsweredQuestions()
      .reduce((map, q) => {
        map[q.game_question_id] = q.correct
        return map
      }, {})

    const accessible = SubService.getAccessiblePackages()

    // Get all accessible questions and shuffle
    const { data: questions } = await StrapiService.searchContentType(
      'game-questions/random',
      {
        filters: {
          package_id: {
            $in: accessible
          },
        }
      },
      {
        answered
      })

    return questions
  },

  /**
   * Shorthand for resolving question packages for answered questions
   * @returns {Promise<HttpResponse>}
   */
  resolveQuestionPackages () {
    return StrapiService.getContentType('game-questions', {
      fields: [
        'id',
        'package_id'
      ]
    })
  },

  isLocked(score, plan) {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const isAccessRestricted = plan !== ACCESS_PRO && score < GAME_THRESHOLD;

    return isDevelopment ? false : isAccessRestricted;
  }
}

export default GameService
