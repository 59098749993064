import Vue from 'vue'
import { FacebookLogin } from '@capacitor-community/facebook-login'
import { Capacitor } from '@capacitor/core'
import AuthService from '/src/services/AuthService'
import { User } from '/src/store'
import Crashlytics from '@/services/Crashlytics'

const store = Vue.observable({
  isAuth: false
})

const FACEBOOK_API_BASE_URL = 'https://graph.facebook.com/v17.0'
// const FACEBOOK_APP_ID = '1392191511166013' // Test
const FACEBOOK_APP_ID = '472774652874983' // Prod
const FACEBOOK_PERMISSIONS = [
  'email',
  'public_profile'
]

const Facebook = {
  async init () {
    // console.log('Facebook init...')
    return new Promise((resolve, reject) => {
      if (Capacitor.getPlatform() === 'web') {
        // wait for facebook sdk to initialize before starting the vue app
        window.fbAsyncInit = function () {
          // eslint-disable-next-line no-undef
          FB.init({
            appId: FACEBOOK_APP_ID,
            cookie: true,
            xfbml: true,
            version: 'v12.0'
          })

          resolve()
        }

        Vue.loadScript('https://connect.facebook.net/en_US/sdk.js')
          .catch(e => {
            console.warn('Failed loading sdk')
            reject(e)
          })
        // load facebook sdk script
        // (function (d, s, id) {
        //   const fjs = d.getElementsByTagName(s)[0]
        //   if (d.getElementById(id)) { return }
        //   const js = d.createElement(s)
        //   js.id = id
        //   js.src = 'https://connect.facebook.net/en_US/sdk.js'
        //   fjs.parentNode.insertBefore(js, fjs)
        // }(document, 'script', 'facebook-jssdk'))
      } else {
        resolve()
      }
    })
  },

  async verify () {
    // console.log('FB verify: verifying...')
    // auto authenticate with the api if already logged in with facebook
    const { accessToken } = await FacebookLogin.getCurrentAccessToken()

    console.log('FB verify: accessToken', accessToken)

    if (accessToken) {
      store.isAuth = true
      // console.log('FB verify: is authenticated', accessToken)

      // This is similar to registration process from FacebookSignInMixin
      const { email, id: facebookId, name: nickname } = await Facebook.getProfile()

      console.log('FB verify: profile', { email, facebookId, nickname })

      if (AuthService.isAuth()) {
        // console.log('FB verify: already authenticated with FH. Will refresh local user data...')
        if (User.getUser().facebook_id === facebookId) {
          await AuthService.refreshUser()
        } else {
          // console.log('FB verify: FB and FH auth don't match. Will log out...')
          await AuthService.logout()
        }
      } else {
        console.log('FB verify: not authenticated with FH. Will log in.')
        try {
          await AuthService.authenticate({ facebookId, email, nickname })
        } catch (e) {
          console.warn('ForexHero authentication failed', e)
          Crashlytics.recordExceptionWithStacktrace('ForexHero authentication failed', e)
        }
      }
    } else {
        // console.log('FB verify: not authenticated')
        // Do not invalidate ForexHero login
    }
  },

  async login () {
    let accessToken

    try {
      const { accessToken: at } = await FacebookLogin.getCurrentAccessToken()
      accessToken = at
    } catch (e) {
      console.warn('FB login: access token doesnt exist')
    }

    if (!accessToken?.token) {
      const response = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
      accessToken = response.accessToken
    }

    if (accessToken) {
      // Login successful.
      // console.log(`FB login: returning token ${accessToken.token}`)
      store.isAuth = true
    }

    return accessToken
  },

  logout () {
    store.isAuth = false
    return FacebookLogin.logout()
  },

  getProfile () {
    return FacebookLogin.getProfile({ fields: ['id', 'email', 'name'] })
  },

  // async getFriends () {
  //   // await FacebookLogin.getProfile({ fields: ['friends'] })
  //   const { accessToken: at } = await FacebookLogin.getCurrentAccessToken()
  //
  //   return new Promise((resolve, reject) => {
  //     if (!at.token) reject(new Error('Facebook access token missing'))
  //
  //     if (Capacitor.isNativePlatform()) {
  //       const params = new URLSearchParams();
  //       params.append('access_token', at.token);
  //       params.append('fields', 'id');
  //       const url = `${FACEBOOK_API_BASE_URL}/me/friends?${params}`;
  //
  //       fetch(url, { method: 'GET' })
  //         .then(({ data }) => resolve(data))
  //     } else {
  //       // eslint-disable-next-line no-undef
  //       FB.api('/me/friends', 'get', { access_token: at.token, fields: 'id' }, ({ data }) => resolve(data))
  //     }
  //   })
  // },

  // async gameRequests () {
  //   // eslint-disable-next-line no-undef
  //   // return FB.ui({
  //   //   method: 'apprequests',
  //   //   href: 'https://developers.facebook.com/docs/'
  //   // }, function (response) {})
  //   // eslint-disable-next-line no-undef
  //   return FB.ui({
  //     method: 'apprequests',
  //     title: 'blblb',
  //     message: 'YOUR_MESSAGE_HERE'
  //   }, function (response) {
  //     console.log(response)
  //   })
  // },

  store () {
    return store
  }
}

export default Facebook
