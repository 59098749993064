// Works on iOS 13+:
// with relative path to public folder: assets/money.XXXXX.wav
// - Doesn't work with global uri capacitor://localhost/assets/money.wav
// Android API 32:
// - with local relative path assets/money.XXXX.wav (it is appended to "public/..." path for android assets)
// import { SoundEffect } from 'capacitor-sound-effect'

// This is fork of community plugin for capacitor v4
// Works with path relative to build folder e.g. public/sounds/money.mp3
// import { NativeAudio } from '@capgo/native-audio'

// Doesn't work on ios 13, crash App[65197:4969039] [shim] BKSProcessAssertion "ConnectionTerminationWatchdog" initialized with invalid pid and restarts
// On ios 14+ generate warning "originator doesn't have entitlement com.apple.runningboard.assertions.webkit AND originator doesn't have entitlement com.apple.multitasking.systemappassertions"
// works with both absolute local urls and global uri capacitor://...
import { Howl } from 'howler'

import Settings from '/src/store/Settings'

const sfxes = new Map()

// const SoundService = {
//   async load (assetId, path) {
//     if (sfxes.has(assetId)) return
//
//     // remove leading slash
//     path = path.replace(/^\//, '')
//
//     const params = {
//       assetId,
//       assetPath: path,
//       isUrl: false
//     }
//
//     await NativeAudio.preload(params)
//
//     sfxes.set(assetId, path)
//   },
//
//   async loadAndPlay(id, src) {
//     if (!sfxes.has(id)) {
//       await SoundService.load(id, src)
//     }
//
//     await SoundService.play(id)
//   },
//
//   play(id) {
//     if (!Settings.getSound()) return
//
//     // console.log('SoundService: playing', id)
//     return NativeAudio.play({ assetId: id })
//   },
//
//   async remove(id) {
//     await NativeAudio.unload({ assetId: id })
//     sfxes.delete(id)
//   }
// }

// SoundEffects implementation
// const SoundService = {
//   load (id, path) {
//     const p = path.replace(/^\//, '')
//     return SoundEffect.loadSound({ id, path: p })
//       .then(() => sfxes.set(id, p))
//   },
//
//   play (id) {
//     if (!Settings.getSound()) return
//     return SoundEffect.play({ id })
//   },
//
//   async loadAndPlay(id, src) {
//     if (!sfxes.has(id)) {
//       await SoundService.load(id, src)
//     }
//
//     return SoundService.play(id)
//   },
//
//   remove (id) {
//     console.warn('SoundEffect unload not implemented', id)
//     // sfxes.delete(id)
//   }
// }

// Howler implementation
const SoundService = {
  async load (id, src, options = {}) {
    return new Promise((resolve, reject) => {

      if (sfxes.has(id)) {
        return resolve(sfxes.get(id))
      }

      const defaultOptions = {
        src: [src],
        onload: () => {
          sfxes.set(id, sfx)
          resolve(sfx)
        },
        onloaderror: (id, error) => {
          reject(new Error(error))
        }
      }

      const sfx = new Howl(Object.assign(defaultOptions, options))
    })
  },

  loadAndPlay (id, src) {
    if (sfxes.has(id)) {
      return SoundService.play(id)
    } else {
      return SoundService.load(id, src, { autoplay: true })
    }
  },

  play (id) {
    // TODO use howler configuration noAudio or mute instead - needs binding or event bus
    if (Settings.getSound()) {
      sfxes.get(id).play()
    }
  },

  remove (id) {
    if (sfxes.has(id)) {
      sfxes.get(id).unload()
      sfxes.delete(id)
    }
  }
}

export default SoundService
