<template>
  <label class="flex items-center cursor-pointer" :for="name" @click="emit()">
    <input type="hidden" :value="value" :name="name"/>
      <img :src="comp" class="mr-4 w-8 h-8"/>
      {{label}}
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    name: {
      type: String
    },
    value: {
      type: Boolean,
      required: true
    },
    label: {
      type: String
    },
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    }
  },
  computed: {
    comp () {
      return this.value === this.trueValue
        ? new URL('/src/assets/check-true.svg?url', import.meta.url).href
        : new URL('/src/assets/check-false.svg?url', import.meta.url).href
    },
    externalValue () {
      if (this.value === true) {
        return this.trueValue
      } else {
        return this.falseValue
      }
    }
  },
  methods: {
    emit () {
      const externalValue = this.value === this.trueValue ? this.falseValue : this.trueValue
      this.$emit('input', externalValue)
    }
  }
}
</script>

<style scoped>

</style>
