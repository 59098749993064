<template>
    <f-button-toggle
            :key="product.product.identifier"
            :label="label"
            :sublabel="product.product.priceString"
            :value="value === product.product.identifier"
            @input="$emit('input', product.product.identifier)"
            :show-ribbon="showRibbon"
            :ribbon="ribbon"
            :horizontal-layout="horizontalLayout"
    />
</template>

<script>
import FButtonToggle from '/src/components/button/ButtonToggle.vue'

export default {
  name: 'PackageChoice',
  components: { FButtonToggle },
  props: {
    horizontalLayout: {
      type: Boolean
    },
    product: {
      type: Object,
      required: true
    },
    showRibbon: {
      type: Boolean
    },
    ribbon: {
      type: String
    },
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    label() {
      const key = `upgrade.packageType.${this.product.packageType}`

      return this.$t(key) === key
        ? this.product.packageType
        : this.$t(`upgrade.packageType.${this.product.packageType}`)
    }
  }
}
</script>

<style scoped>

</style>