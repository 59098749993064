import Vue from 'vue'
import StorageService from '@/services/StorageService'

const key = 'quiz'

const store = Vue.observable({
  meta: {
    dirty: false
  },
  data: {
    questions: []
  }
})

async function persist () {
  store.meta.dirty = true
  await StorageService.set(key, store)
}

function parseLegacyValues (d = {}) {
  d.data?.questions?.forEach(q => {
    q.question_id = Number(q.question_id)
    // true, 1, '1' as true
    q.correct = q.correct === true || q.correct === 1 || q.correct === '1'
  })

  return d
}

const Quiz = {
  key,

  getStore () {
    return store
  },

  setStore (d = {}) {
    Object.assign(store, parseLegacyValues(d))
  },

  getAnsweredQuestions: () => {
    return store.data.questions
  },

  /**
   *
   * @param {Number} questionId
   * @param {Boolean} correct
   * @returns {Promise<void>}
   */
  addAnsweredQuestion: async (questionId, correct) => {
    const q = store.data.questions.find(q => q.question_id === questionId)
    const c = correct ? '1' : '0'

    if (q) {
      q.correct = c
    } else {
      store.data.questions.push({
        question_id: questionId,
        correct: c
      })
    }

    await persist()
  },

  reset: async (d = {}) => {
    const data = Object.assign({ questions: [] }, d)
    Quiz.setStore({ meta: { dirty: false }, data })
    await persist()
  }
}

export default Quiz
