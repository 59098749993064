import Vue from 'vue'
import StorageService from '@/services/StorageService'
import { v4 as uuid } from 'uuid'
import ApiService from '@/services/ApiService'
import { Device } from '@capacitor/device'
import { Settings } from '@/store'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import NotificationsService from '@/services/NotificationsService'
import Analytics from '@/services/Analytics'
import SubService from '@/services/SubService'
import { FirebaseApp } from '@capacitor-firebase/app';

const STORAGE_KEY = 'app_id'

const store = Vue.observable({
  appId: null,
  info: {}
})

export default {
  async init () {
    let appId = await StorageService.get(STORAGE_KEY)
    store.appId = appId

    // If no app id is stored, generate a new one and post to server otherwise update on selected events
    // TODO: move event binding here
    if (!appId) {
      await this.generateAppId()
    }
  },

  async generateAppId () {
    const appId = uuid()
    await StorageService.set(STORAGE_KEY, appId)
    store.appId = appId
  },

  getAppId () {
    return store.appId
  },

  getAppInfo () {
    return store.info
  },

  async syncAppInstanceInfo () {
    if (!store.appId || !Capacitor.isNativePlatform()) return

    try {
      const info = await this.collectAppInfo()

      const { data } = await ApiService.updateAppInstance(store.appId, info)

      Object.keys(data).forEach(key => {
        Vue.set(store.info, key, data[key])
      })
    } catch (e) {
      console.warn('Failed to sync app instance info', e)
    }
  },

  async collectAppInfo () {
    const [device, appInfo, deviceId, meta, token] = await Promise.all([
      Device.getInfo(),
      Capacitor.isNativePlatform() ? App.getInfo() : null,
      Device.getId(),
      StorageService.get('meta'),
      NotificationsService.getToken().catch(() => null)
    ])

    const [
      analyticsId,
      revenueCatUserId,
      { applicationId: firebaseAppId }
    ] = await Promise.all([
      Analytics.getAppInstanceId().catch(() => null),
      SubService.getRevenueCatUserId().catch(() => null),
      FirebaseApp.getOptions().catch(() => ({}))
    ])

    return {
      id: store.appId,
      fcm_token: token?.token,
      device_uuid: deviceId.identifier,
      device_platform: device.platform,
      device: device,
      app_version: appInfo?.version !== undefined
        ? appInfo.version + ' (' + appInfo.build + ')'
        : null,
      app_language: Settings.getLanguage(),
      content_version: meta?.version,
      revenuecat_id: revenueCatUserId,
      firebase_analytics_id: analyticsId,
      firebase_app_id: firebaseAppId
    }
  },

  async resetAppId () {
    await StorageService.delete(STORAGE_KEY)
    await this.generateAppId()
    await this.syncAppInstanceInfo()
  },

  registerAppsflyerConversion (data) {
    if (!store.appId) return
    return ApiService.registerAppsflyerConversion(store.appId, data)
  },

  registerAppEvent (type, message, data) {
    if (!Capacitor.isNativePlatform()) return

    return ApiService.createAppEvent({
      type,
      message,
      data,
      event_created_at: new Date().toISOString(),
    }).catch(e => console.error(e))
  }
}
