<template>
    <div class="bg-backdrop flex flex-col flex-nowrap h-screen">
        <slot/>
    </div>
</template>

<script>
/**
 * Low level container for building app layout.
 * Wraps app header and app body elements.
 */
export default {
  name: 'AppScreen'
}
</script>
