import { Game, School, Scores, User, Quiz } from '/src/store'
import { Network } from '@capacitor/network'

export default {
  /**
   * Set up network state listener to trigger sync on reconnection.
   * @return {Promise<void>}
   */
  async init () {
    // console.log('AuthUserStateService.init: Adding network change listener')
    Network.addListener('networkStatusChange', async (status) => {
      // console.log('networkStatusChange: network changed', status)
      if (status.connected && User.getUser()) {
        // Sync user states if dirty
        await Promise.all([Game, School, Scores]
          .map(s => s.sync()))
      }
    })

    const { connected } = await Network.getStatus()

    if (connected && User.getUser()) {
      // console.log('AuthUserStateService.init: syncing storages if dirty')
      await Promise.all([Game, School, Scores]
        .map(s => s.sync()))
    }
  },

  /**
   * Upload dirty data
   * @param user
   * @return {Promise<void>}
   */
  async sync (user) {
    // Sync or update local data
    if (user.is_new_user === '1' || user.is_new_user === true || user.is_new_user === 1) {
      // console.log('AuthUserStateService.sync: will sync storages....')
      // Push local data to server for new users or
      // if was already authenticated but missing FB auth
      await Promise.all([
        Scores.sync(),
        Game.sync(),
        School.sync()
      ])
    } else {
      // Overwrite local storage data from server for returning user. Will not consider dirty state.
      // console.log('AuthUserStateService.sync: will reset storages....')
      await Promise.all([
        Scores.reset(user),
        Game.reset({
          questions: user.seen_game_questions
        }),
        School.reset({
          topics: user.seen_topics
        }),
        Quiz.reset()
      ])
    }
  },

  isDirty () {
    return [Game, School, Scores]
      .some(store => store.isDirty())
  },

  async reset () {
    return Promise.all([
      Game.reset(),
      School.reset(),
      Scores.reset(),
      Quiz.reset()
    ])
  }
}
