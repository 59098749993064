<template>
  <div class="f-button-group" :class="classes">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'ButtonGroup',
  props: {
    rounded: Boolean,
    outline: Boolean
  },
  computed: {
    classes () {
      return {
        'f-button-group--outline': this.outline,
        'f-button-group--rounded': this.rounded
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .f-button-group {
    > *:not(:first-child) {
      margin-left: -1px;
    }

    &--rounded {
      > *:first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      > *:last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
</style>
