<template>
    <f-fullscreen-modal ref="modal" @close="reset()">
        <f-app-screen style="background-color: rgba(0, 0, 0, 0.50);">
            <f-app-body class="flex flex-col flex-nowrap">
                <div class="w-full safe-area-top">
                    <div class="py-3 px-4 relative text-xs flex items-center justify-center h-14">
                        Special offer from our partner

                        <a href="#" @click.prevent="discard()" class="rounded-full absolute top-3 right-4">
                            <img src="/src/assets/icons/close-circle.svg" alt="Close"/>
                        </a>
                    </div>
                </div>

                <f-loading :showing="loading" class="flex-grow" color="#ffffff"/>
                <iframe
                        v-if="promo"
                        v-show="!loading"
                        ref="promoFrame"
                        :src="promo.url"
                        class="flex-grow"
                        sandbox="allow-scripts allow-same-origin allow-popups"
                        @load="onIframeLoad"
                ></iframe>
            </f-app-body>
        </f-app-screen>
    </f-fullscreen-modal>
</template>

<script>
import { clearPromo } from '@/services/PromoService'

export default {
  name: 'Promo',
  data() {
    return {
      promo: null,
      resolve: null,
      loading: false
    }
  },
  methods: {
    async open(promo) {
      this.loading = true
      this.promo = promo

      await this.$nextTick()
      await this.$refs.modal.open()
    },
    async discard() {
      await this.$refs.modal.close()
      await this.$nextTick()
      clearPromo()
      if (this.promo.callback && typeof this.promo.callback === 'function') {
        await this.promo.callback()
      }
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this))
    },
    onIframeLoad() {
      this.loading = false
    }
  },
  mounted () {
    // listen to messages from iframe $refs.promoFrame
    window.addEventListener('message', (event) => {
      // console.log('message from iframe', event.data)
      this.discard()
    })
  }
}
</script>

<style scoped>

</style>
