import { Network } from '@capacitor/network'
import Vue from 'vue'

let notice;
export default {
  ensureNetwork: async function (callback) {
    const { connected } = await Network.getStatus()

    if (connected) {
      return callback ? callback() : Promise.resolve()
    } else {
      notice = Vue.toasted.show('No network connection!', {
        duration: 0,
        position: 'top-center'
      })

      return new Promise((resolve) => {
        const networkListener = Network.addListener('networkStatusChange', async ({ connected }) => {
          if (connected) {
            await networkListener.remove()
            notice.goAway(0)
            const result = callback ? callback() : Promise.resolve()
            return resolve(result)
          }
        })
      })
    }
  }
}
