<template>
    <div id="app" class="bg-backdrop">
        <splash v-if="!loaded" class="h-screen" :progress="loadingProgress"/>

        <language v-else-if="!userLanguage"/>

        <tutorials v-else-if="!seenTutorials" @finished="finishedTutorial"/>

        <router-view v-else class="min-h-screen"/>

        <template v-if="loaded">
            <f-dialog
                    ref="gameUnlockedNotice"
                    v-if="!seenGameUnlockedNotice"
                    :title="$t('trend.predictor.unlock.title')"
                    :message="$t('trend.predictor.unlock.description')"
                    :okLabel="$t('trend.predictor.unlock.button.ok')"
                    :cancelLabel="$t('trend.predictor.unlock.button.cancel')"
                    :content-classes="['text-white', 'bg-positive', 'bg-opacity-90']"
            />

            <upgrade-sheet ref="upgradeSheet"/>
        </template>

        <promo ref="promo"/>

        <portal-target name="modals" multiple/>

        <portal-target name="fullscreen-modals" multiple/>
    </div>
</template>

<script>
import GameService, { GAME_THRESHOLD } from '@/services/GameService'
import SoundService from '@/services/SoundService'
import Splash from '@/modules/core/Splash.vue'
import Language from '@/modules/core/Language.vue'
import Tutorials from '@/modules/core/Tutorials.vue'
import { Scores, Settings, User } from '@/store'
import { Network } from '@capacitor/network'
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update'
import { Capacitor } from '@capacitor/core'
import LocalesService from '@/services/LocalesService'
import SubService from '@/services/SubService'
import gameUnlockedSound from '@/assets/sounds/game_unlocked.mp3'
import AppService from '@/services/AppService'
import UpgradeSheet from '@/components/subscriptions/UpgradeSheet.vue'
import ProgressManager from '@/services/ProgressManager'
import Facebook from '@/services/Facebook'
import AuthUserStateService from '@/services/AuthUserStateService'
import { Device } from '@capacitor/device'
import NotificationsService from '@/services/NotificationsService'
import Analytics from '@/services/Analytics'
import Crashlytics from '@/services/Crashlytics'
import Promo from '@/components/Promo.vue'
import { getPromo, clearPromo } from '@/services/PromoService'
import networkService from '@/services/NetworkService'
import ConfigService from '@/services/ConfigService'

export default {
  name: 'App',

  components: {
    Promo,
    UpgradeSheet,
    Tutorials,
    Language,
    Splash
  },

  data () {
    return {
      initting: false,
      networkNotice: null,
      loaded: false,
      seenGameUnlockedNotice: Settings.getParam('seenGameUnlockedNotice', false),
      seenTutorials: Settings.getParam('seenTutorials', false),
      // webViewVersion: null
    }
  },

  computed: {
    balance: function () {
      return Scores.getBalance()
    },
    // gameLocked: function () {
    //   return GameService.isLocked(this.balance, this.plan)
    // },
    userLanguage: function () {
      return Settings.getLanguage()
    },
    plan: function () {
      return SubService.state.plan
    },
    loadingProgress: function () {
      return ProgressManager.state.overallProgress
    },
    promo: function () {
      return getPromo()
    }
  },

  watch: {
    balance: function (val, old) {
      if (
        GameService.isLocked(old, this.plan)
        && val >= GAME_THRESHOLD
        && !Settings.getParam('seenGameUnlockedNotice', false)
      ) {
        SoundService.loadAndPlay('trendPredictor.gameUnlocked', gameUnlockedSound)

        this.$refs.gameUnlockedNotice.open()
          .onOk(() => this.$router.push({ name: 'game' }))

        Settings.setParam('seenGameUnlockedNotice', true)
      }
    },
    userLanguage: function () {
      // TODO move to AppService init with Vue 3 observers
      AppService.syncAppInstanceInfo()
    },
    // FIXME use event bus maybe?
    promo (val) {
      if (val?.url) {
        this.$refs.promo.open(val)
      } else {
        clearPromo()
      }
    }
  },

  methods: {
    /**
     * Initialize services. Services should not fail the app initialization in offline mode.
     * @returns {Promise<void>}
     */
    async initializeServices () {
      // Initial sync of app instance info to get country code etc.,
      try {
        await AppService.syncAppInstanceInfo()
        console.log('Initializing services: App instance info synced')

        // Set user ID for analytics and crashlytics
        const appInstanceId = AppService.getAppId()

        await Promise.all([
          Analytics.setUserId(appInstanceId),
          Crashlytics.setUserId(appInstanceId)
        ])
      } catch (e) {
        console.warn('Failed to sync app instance info', e)
      }

      console.log('Initializing services: Facebook')
      // Check FB token and login state
      await Facebook.verify()
        .catch(e => {
          console.warn('Failed to verify Facebook token')
          Crashlytics.recordExceptionWithStacktrace('Failed initialising authentication state', e)
        })

      console.log('Initializing services: AuthUserStateService')
      // Sync user progress if needed
      await AuthUserStateService.init()
        .catch(e => Crashlytics.recordExceptionWithStacktrace('Failed to sync authenticated user progress', e))

      console.log('Initializing services: NotificationsService')
      // Request notifications permission
      await NotificationsService.init()
        .catch(e => Crashlytics.recordExceptionWithStacktrace('Notifications initialization failed', e))

      console.log('Initializing services: RevenueCat')
      // Initialize RevenueCat
      await this.initializeRevenueCat()
        .catch(e => Crashlytics.recordExceptionWithStacktrace('RevenueCat initialization failed', e))
    },
    async initializeRevenueCat () {
      const { id, email, nickname: displayName } = User.getUser() || {}
      await SubService.init({ id, email, displayName })
      // Refresh if possible (online)
      SubService.refreshCustomerInfo()
    },
    finishedTutorial ({ showPro }) {
      this.seenTutorials = true
      Settings.setParam('seenTutorials', true)
      AppService.registerAppEvent('tutorial_finished')

      if (showPro) {
        this.$refs.upgradeSheet.open()
      }
    },
    async init () {
      if (this.initting) return

      // Prevent multiple init calls
      this.initting = true

      try {
        // Set up progress stages
        this.setUpProgressStages()

        // Load translations. Stop if fails.
        await LocalesService.init(Settings.getLanguage() || 'en')

        ProgressManager.setStageProgress('LOCALISATION', 100)

        // Initialize non-critical services
        await this.initializeServices()
          .catch(e => {
            // This will never happen, but just in case
            Crashlytics.recordExceptionWithStacktrace('Failed to initialize services', e)
            throw e
          })

        ProgressManager.setStageProgress('SERVICES', 100)

        ProgressManager.complete()

        // Wait for progress animation to complete
        setTimeout(() => {
          this.loaded = true
        }, 500)// 2x anim length for graceful pause
      } catch (e) {
        // Based on network status, show a notice to the user and log the error
        const { connectionType, connected } = await Network.getStatus()

        // Record exception and original error message
        await Promise.all([
          Crashlytics.setContext({ key: 'connectionType', value: connectionType, type: 'string' }),
          Crashlytics.setContext({ key: 'connected', value: connected, type: 'boolean' })
        ])

        Crashlytics.recordExceptionWithStacktrace('Failed to initialise' + (e.message ? ': ' + e.message : ''), e)

        if (connected) {
          this.$toasted.show(e.message ?? 'Failed to start.', {
            duration: 0,
            position: 'top-center',
            action: {
              text: 'Retry',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
                this.init()
              }
            }
          })
        }
      } finally {
        this.initting = false
      }
    },
    onNetworkChange (status) {
      if (!status.connected) return

      if (this.networkNotice) {
        this.networkNotice
          .text('Network connected!')
          .goAway(3000)
      }

      this.init(status)
    },
    async verifyBrowser () {
      const { webViewVersion, platform } = await Device.getInfo()
      const version = webViewVersion?.split('.').shift()
        // this.webViewVersion = webViewVersion

      if (platform === 'android' && version < 60) {
        this.$toasted.show('Please update your webview component.', {
          duration: 0,
          position: 'top-center',
          action: [
            {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              }
            }
          ]
        })

        return false
      }

      return true
    },
    async checkAppUpdate () {
      const result = await AppUpdate.getAppUpdateInfo()

      if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
        this.$toasted.show('New update available!', {
          duration: 0,
          position: 'top-center',
          action: [
            {
              text: 'Update now',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
                AppUpdate.openAppStore()
              }
            }
          ]
        })
      }
    },
    setUpProgressStages () {
      ProgressManager.resetProgressStages()

      ProgressManager.setProgressStage('LOCALISATION', 0, 20)
      ProgressManager.setProgressStage('SERVICES', 20, 100)
    }
  },

  async created () {
    const { connected } = await Network.getStatus()

    // console.log('Network status: %s', (connected ? 'connected' : 'disconnected'))

    // Check for outdated browsers on Android
    await this.verifyBrowser()

    // It will not go fyrther if there is no network
    try {
      await networkService.ensureNetwork(() => this.init())
    } catch (e) {
      console.warn('Failed to initialize app', e)
    } finally {
    }

    // Check for update and show notice
    if (connected && Capacitor.isNativePlatform()) {
      await this.checkAppUpdate()
          .catch(e => console.warn('Failed to check for update', e))
    }
  },

  async mounted () {
    AppService.registerAppEvent('app_launch')

    const cmsDomain = await ConfigService.getString('cms_url')
    const link = document.createElement('link');
    link.rel = 'stylesheet'
    link.href = `${cmsDomain}/school.css`
    document.head.appendChild(link)
  },

  async beforeDestroy () {
    SoundService.remove('trendPredictor.gameUnlocked')

    if (this.networkListener) {
      await this.networkListener.remove()
    }
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
