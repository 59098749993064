import { Preferences } from '@capacitor/preferences'

const StorageService = {
  async get (key, defaultValue) {
    const { value } = await Preferences.get({ key })
    return value ? JSON.parse(value) : defaultValue
  },

  async set (key, value) {
    await Preferences.set({ key, value: JSON.stringify(value) })
  },

  async delete (key) {
    await Preferences.remove({ key })
  }
}

export default StorageService
