import * as StackTrace from 'stacktrace-js'
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics'
import { Capacitor } from '@capacitor/core'

export default {
  async crash(message) {
    await FirebaseCrashlytics.crash({ message })
  },

  setContext ({ key, value, type }) {
    if (!Capacitor.isNativePlatform()) return

    return FirebaseCrashlytics.setCustomKey({
      key,
      value,
      type
    }).catch(() => console.warn(`Failed to set context: ${key}`))
  },

  setUserId (userId) {
    if (!Capacitor.isNativePlatform()) return

    return FirebaseCrashlytics.setUserId({ userId })
      .catch(() => console.warn(`Failed to set user id: ${userId}`))
  },

  async recordException (message) {
    if (Capacitor.isNativePlatform()) {
      await FirebaseCrashlytics.recordException({ message })
        .catch(() => console.log('Failed to record exception'))
    } else {
      console.warn(message)
    }
  },

  async recordExceptionWithStacktrace (message, error) {
    if (Capacitor.isNativePlatform()) {
      const stacktrace = await StackTrace.fromError(error)
      await FirebaseCrashlytics.recordException({
        message,
        stacktrace
      }).catch(() => console.log('Failed to record exception'))
    } else {
      console.warn(message, { error })
    }
  }
}
