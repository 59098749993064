<template>
    <f-fullscreen-modal ref="modal">
        <f-app-screen>
            <f-modal-header @close="close()">{{ $t('profile') }}</f-modal-header>

            <f-app-body>
                <div class="p-10 text-center ">
                    <div class="text-lg font-bold mb-4">{{ $t('delete.profile.title') }}</div>

                    <p class="text-sm">{{ $t('delete.profile.description') }}</p>

                    <div class="flex justify-between mt-8">
                        <link-button @click="deleteProfile()" class="text-primary">{{
                                $t('delete.my.profile')
                            }}
                        </link-button>

                        <link-button @click="close()">{{ $t('cancel') }}</link-button>
                    </div>
                </div>
            </f-app-body>
        </f-app-screen>
    </f-fullscreen-modal>
</template>

<script>
import LinkButton from '/src/components/LinkButton.vue'
import AuthService from '/src/services/AuthService'
import User from '/src/store/User'
import ApiService from '/src/services/ApiService'
import Facebook from '/src/services/Facebook'
import Analytics, { SCREEN_DELETE_PROFILE } from '/src/services/Analytics'

export default {
  name: 'DeleteProfileModal',
  components: { LinkButton },
  data () {
    return {
      resolve: null
    }
  },
  methods: {
    async open () {
      await this.$refs.modal.open()
      Analytics.setScreenName(SCREEN_DELETE_PROFILE)
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    close () {
      return this.$refs.modal?.close()
    },
    async deleteProfile () {
      if (!AuthService.isAuth()) return
      await ApiService.deleteUser(User.getUser().id)
      await AuthService.logout()
      await Facebook.logout()
      await this.$refs.modal.close()
      this.resolve()
    }
  }
}
</script>
