import PortalVue from 'portal-vue'
import LoadScript from 'vue-plugin-load-script'
import VueResizeObserver from 'vue-resize-observer'
import Toasted from 'vue-toasted'

export default function ({ Vue, router }) {
  Vue.use(PortalVue)
  Vue.use(LoadScript)
  Vue.use(VueResizeObserver)
  Vue.use(Toasted, { router })
}
