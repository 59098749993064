import Vue from 'vue'

const state = Vue.observable({
  progressStages: {},
  progresses: {},
  overallProgress: 0
});

/**
 * Handle loading progress in stages
 */
export default {
  setProgressStage(key, start, end) {
    Vue.set(state.progressStages, key, { start, end })
  },

  resetProgressStages() {
    Vue.set(state, 'progressStages', {})
  },

  setStageProgress(key, progress, refresh = true) {
    // console.log('Setting progress for stage %s to %s', key, progress)
    Vue.set(state.progresses, key, progress)

    if (refresh) {
      this.refreshOverallProgress()
    }
  },

  complete() {
    const stages = Object.keys(state.progressStages)
    stages.forEach(stage => {
      this.setStageProgress(stage, 100, false)
    })

    this.refreshOverallProgress()
  },

  refreshOverallProgress() {
    const progressValues = Object.values(state.progresses)
    const progressStages = Object.values(state.progressStages)

    // Calculate the weighted progress for each stage and sum them up
    state.overallProgress = progressValues.reduce((acc, progress, index) => {
      const { start, end } = progressStages[index]
      const weight = (end - start) / 100
      return acc + (Number(progress) * weight)
    }, 0)
  },

  state,
}
