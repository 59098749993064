<template>
    <f-fullscreen-modal ref="bottomSheet" @hide="reset()">
        <f-app-screen>
            <f-app-body class="flex flex-col flex-nowrap">
                <div class="flex flex-col flex-nowrap relative">
                    <div class="absolute w-full safe-area-top">
                        <div class="pt-3 xs:pt-6 px-4 flex justify-end">
                            <a href="#" @click.prevent="$refs.bottomSheet.close()" class="rounded-full">
                                <img src="/src/assets/icons/close-circle.svg" alt="Close"/>
                            </a>
                        </div>
                    </div>

                    <div class="absolute tall:static tall:from-backdrop-600 tall:to-backdrop-600 bg-gradient-to-b from-transparent to-backdrop-dark bottom-0 order-2 flex justify-around items-center px-4 py-6 tall:py-2 w-full">
                        <img
                                src="@/assets/upgrade-social-proof-1@2x.png"
                                srcset="@/assets/upgrade-social-proof-1@2x.png 2x, @/assets/upgrade-social-proof-1@3x.png 3x"
                        />

                        <img
                                src="@/assets/upgrade-social-proof-2@2x.png"
                                srcset="@/assets/upgrade-social-proof-2@2x.png 2x, @/assets/upgrade-social-proof-2@3x.png 3x"
                        />
                    </div>

                    <img
                            src="@/assets/upgrade-trader@2x.png"
                            srcset="@/assets/upgrade-trader@2x.png 2x, @/assets/upgrade-trader@3x.png 3x"
                            class="w-full order-1 -mt-5 tall:mt-0"
                    />
                </div>

                <div class="text-center px-6 tall:py-6 py-3">
                    <h1 class="mb-3 xs:mb-3 tall:text-3xl text-xl">{{ $t('upgrade.pro.title') }}</h1>
                    <p class="text-sm text-x-100">{{ $t('upgrade.pro.subtitle') }}</p>

                    <ul class="text-left text-sm mt-6">
                        <li>{{$t("upgrade.benefits.school")}}</li>
                        <li>{{$t("upgrade.benefits.trend.predictor", { count: gameProCount })}}</li>
                        <li>{{$t("upgrade.benefits.quiz", { count: quizProCount })}}</li>
                        <li>{{$t("upgrade.benefits.ads")}}</li>
                    </ul>
                </div>

                <div class="text-center px-6 pb-3 xs:pb-6 mt-auto">
                    <div class="relative" v-if="loading">
                        <f-loading :showing="loading" color="#FFFFFF"/>
                    </div>

                    <div :class="packagesClasses" v-if="packages.length > 0">
                        <package-choice
                                v-for="p in packages"
                                :key="p.product.identifier"
                                :product="p"
                                :value="product"
                                @input="setProduct"
                                :show-ribbon="featured.product === p.product.identifier"
                                :ribbon="featured.save"
                                :horizontal-layout="false"
                        />
                    </div>

                    <template v-if="isPro">
                        <div class="bg-x-800 p-4 rounded text-white mt-4 xs:mt-8">{{ $t('upgrade.already.pro') }}</div>
                    </template>

                    <template v-else-if="offline">
                        <div class="bg-primary bg-opacity-20 border border-primary p-4 rounded text-white mt-4 xs:mt-8">{{ $t('upgrade.offline') }}</div>
                    </template>

                    <template v-else-if="!loading">
                        <template v-if="canUpgrade">
                            <nav-button
                                    :label="upgrading ? $t('upgrade.now.button.busy') : $t('upgrade.now.button')"
                                    @click="upgrade()"
                                    class="mt-6"
                                    hide-icon
                                    :busy="upgrading"
                                    :disabled="upgrading"
                            />

                            <div v-if="isAndroid" class="text-xs text-x-100 mt-2">{{ $t('upgrade.google.play.disclaimer') }}</div>
                            <div v-if="isIOS" class="text-xs text-x-100 mt-2">{{ $t('upgrade.appstore.disclaimer') }}</div>
                        </template>

                        <template v-else>
                            <div class="bg-primary bg-opacity-20 border border-primary p-4 rounded text-white mt-4 xs:mt-8">{{ $t('upgrade.unavailable') }}</div>
                        </template>
                    </template>

                    <div class="mt-4 xs:mt-8">
                        <link-button class="text-xs text-x-100" @click="$refs.privacyPolicyModal.open()">{{ $t('privacy.policy') }}</link-button>
                        <link-button class="ml-12 text-xs text-x-100" @click="$refs.termsModal.open()">{{ $t('terms.of.use') }}</link-button>
                    </div>
                </div>
            </f-app-body>
        </f-app-screen>

        <privacy-policy-modal ref="privacyPolicyModal"/>
        <terms-of-use-modal ref="termsModal"/>
        <success-sheet ref="successSheet"/>
    </f-fullscreen-modal>
</template>

<script>
import NavButton from '/src/components/NavButton.vue'
import LinkButton from '/src/components/LinkButton.vue'
import GameService from '/src/services/GameService'
import QuizService from '/src/services/QuizService'
import PrivacyPolicyModal from '/src/modules/settings/PrivacyPolicyModal.vue'
import SuccessSheet from '/src/components/subscriptions/SuccessSheet.vue'
import SubService, { ACCESS_FREE, ACCESS_PRO } from '/src/services/SubService'
import { Capacitor } from '@capacitor/core'
import { cloneDeep } from 'lodash'
import TermsOfUseModal from '/src/modules/settings/TermsModal.vue'
import Analytics, { SCREEN_UPGRADE } from '/src/services/Analytics'
import { Network } from '@capacitor/network'
import AppService from '@/services/AppService'
import PackageChoice from '@/components/subscriptions/PackageChoice.vue'

const UNITS = {
  ANNUAL: 12,
  SIX_MONTH: 6,
  THREE_MONTH: 3,
  TWO_MONTH: 2,
  MONTHLY: 1,
  WEEKLY: 0.25,
  LIFETIME: 0
}

const platform = Capacitor.getPlatform()

export default {
  name: 'UpgradeSheet',
  components: {
    PackageChoice,
    TermsOfUseModal,
    SuccessSheet,
    PrivacyPolicyModal,
    LinkButton,
    NavButton
  },
  data () {
    return {
      // schoolTopics: {
      //   pro: 0,
      //   free: 0
      // },
      gameProCount: 0,
      quizProCount: 0,
      product: 'pro_yearly',
      packages: [],
      featured: {
        product: null,
        save: null
      },
      resolve: null,
      upgrading: false,
      offline: false,
      loading: false
    }
  },
  computed: {
    canUpgrade () {
      return Capacitor.isNativePlatform() &&
        this.isFree &&
        this.packages.length > 0
    },
    isFree () {
      return this.plan === ACCESS_FREE
    },
    isPro () {
      return this.plan === ACCESS_PRO
    },
    plan () {
      return SubService.state.plan
    },
    isAndroid () {
      return platform === 'android'
    },
    isIOS () {
      return platform === 'ios'
    },
    packagesClasses () {
      return {
        'grid gap-2 xs3:gap-3': this.packages.length > 1,
        'grid-cols-2': this.packages.length === 2,
        'grid-cols-3': this.packages.length > 2
      }
    }
  },
  methods: {
    reset () {
      this.upgrading = false
    },
    async open () {
      // Start showing loading to prevent flashing of "no-packagees" message
      this.loading = true

      this.refreshCount()

      await this.$refs.bottomSheet.open()

      Analytics.setScreenName(SCREEN_UPGRADE)
      AppService.registerAppEvent('upgrade_view_open')

      const { connected } = await Network.getStatus()

      if (connected) {
        this.refreshPackages()
      }

      return new Promise((resolve) => {
        this.offline = !connected
        this.resolve = resolve
      })
    },
    setProduct(identifier) {
      this.product = identifier
      AppService.registerAppEvent('upgrade_product_selected', identifier, { product: identifier })
    },
    async refreshCount () {
      const [
        gameStats,
        quizStats
      ] = await Promise.all([
        GameService.getStats(),
        QuizService.getStats()
      ])

      this.gameProCount = gameStats.packages[String(ACCESS_PRO)]?.count ?? 0
      this.quizProCount = quizStats.packages[String(ACCESS_PRO)]?.count ?? 0
    },
    async refreshPackages () {
      try {
        this.loading = true
        const originalPackages = await SubService.getAvailablePackages().catch(() => [])
        const packages = cloneDeep(originalPackages)

        // Set featured package dynamically
        if (packages.length > 0) {
          // Set cheapest as preselected if default (yearly) doesn't exist
          if (!packages.some(p => p.product.identifier === this.product)) {
            this.product = packages[packages.length - 1].product.identifier
          }

          const prices = packages
            // Remove lifetime or free products
            .filter((p) => UNITS[p.packageType] && UNITS[p.packageType] > 0)
            .map((p) => {
              const units = UNITS[p.packageType]
              const price = p.product.price

              return {
                unitPrice: Number((price / units).toFixed(2)),
                package: p
              }
            })
            .sort((a, b) => a.unitPrice - b.unitPrice)

          if (prices && prices.length > 0) {
            const maxPrice = prices[prices.length - 1].unitPrice
            const minPrice = prices[0].unitPrice

            const savePercent = Math.floor((maxPrice - minPrice) / maxPrice * 100)

            this.featured.product = prices[0].package.product.identifier
            this.featured.save = this.$t('upgrade.ribbon.save', { percent: savePercent })
          }
        }

        this.packages = packages
      } finally {
        this.loading = false
      }
    },
    async upgrade () {
      if (this.upgrading) return

      try {
        this.upgrading = true
        AppService.registerAppEvent('upgrade_start', this.product, { product: this.product })
        await SubService.purchase(this.product)
        this.upgrading = false
        AppService.registerAppEvent('upgrade_completed', this.product, { product: this.product })
        await this.$refs.successSheet.open()// resolves on hide
        await this.$refs.bottomSheet.close()// resolves after hide animation
        if (this.resolve) this.resolve()
      } catch (e) {
        AppService.registerAppEvent('upgrade_failed', this.product, { product: this.product, error: e })
      } finally {
        this.upgrading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
li {
  position: relative;
  padding-left: 2.25rem;

  &:before {
    content: "";
    background-image: url('/src/assets/icons/success.svg?url');
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 1.25rem;
    height: 1.25rem;

    position: absolute;
    left: 0;
    top: -.125rem;
  }
}

li + li {
  margin-top: .85rem;
}
</style>
