<template>
    <f-fullscreen-modal ref="modal">
        <f-app-screen>
            <f-modal-header @close="close()">{{ $t('settings.title') }}</f-modal-header>

            <f-app-body>
                <template v-if="isAuth">
                    <div class="py-10 text-center">
                        {{ $t('settings.nickname') }}
                        <div class="text-lg font-bold">{{ user.nickname }}</div>

                        <f-button class="text-sky" icon="edit" @click="showProfile()">
                            {{ $t('edit.profile') }}
                        </f-button>
                    </div>

                    <f-divider/>
                </template>

                <div class="py-10 text-center">
                    <a
                            class="text-sky underline tracking-wider"
                            href="https://www.instagram.com/forexheroapp/"
                            target="_blank"
                    >
                        <img
                                :alt="$t('settings.follow.instagram')"
                                class="inline-block w-16 h-16 mr-4 object-contain"
                                src="@/assets/icons/follow-ig@2x.png"
                                srcset="@/assets/icons/follow-ig@2x.png 2x, @/assets/icons/follow-ig@3x.png 3x, @/assets/icons/follow-ig@4x.png 4x"
                        />
                        <span>{{ $t('settings.follow.instagram') }}</span>
                    </a>
                </div>

                <f-divider/>

                <div class="py-8">
                    <div class="flex justify-between align-middle px-4 py-2">
                        <label class="tracking-wider" for="soundToggle">{{ $t('settings.sound') }}</label>
                        <toggle id="soundToggle" :value="sound" @input="toggleSound()"/>
                    </div>

                    <div class="flex justify-between align-middle px-4 py-2">
                        <label class="tracking-wider" for="vibrationToggle">{{ $t('settings.vibration') }}</label>
                        <toggle id="vibrationToggle" :value="vibration" @input="toggleVibration()"/>
                    </div>
                </div>

                <f-divider/>

                <div v-if="isAuth" class="py-10 px-4 text-center">
                    <p class="mb-8 text-sm">{{ $t('settings.sign.out.title') }}</p>

                    <f-button
                            :loading="authenticating"
                            :disable="authenticating"
                            @click="signOut()"
                            class="mx-auto rounded-lg bg-backdrop-800"
                    >
                        {{ $t('settings.sign.out.button') }}
                    </f-button>
                </div>

                <div v-else class="py-10 px-4 text-center flex flex-col">
                    <p class="mb-8 text-sm">{{ $t('settings.facebook.should.connect') }}</p>

                    <div class="inline-flex flex-col mx-auto">
                        <facebook-button>
                            {{ $t('settings.sign.in.facebook.button') }}
                        </facebook-button>

                        <apple-button v-if="isIOS" class="mt-6">
                            {{ $t('settings.sign.in.apple.button') }}
                        </apple-button>
                    </div>
                </div>

                <f-divider/>

                <div class="py-10 flex flex-col flex-nowrap">
                    <p class="mb-4 text-center">{{ $t('choose.language') }}</p>

                    <div class="inline-grid grid-cols-2 xs3:grid-cols-3 px-4 sm:px-4 md:px-6 gap-2 md:gap-6">
                        <lang-item
                                v-for="(lang) in languages"
                                :key="lang.code"
                                :code="lang.code"
                                :name="lang.name"
                                @click="setUserLanguage"
                                :class="{ 'bg-x-900': lang.code === currentLanguage, 'rounded': true }"
                                :flag-classes="['w-6']"
                        />
                    </div>
                </div>

                <f-divider/>

                <div v-if="isEntitled && !isLifetime" class="py-10 text-center">
                    <link-button
                            class="text-sky"
                            @click="cancelSubscription()"
                    >
                        {{ $t('settings.subscription.cancel') }}
                    </link-button>
                </div>

                <div v-if="!isEntitled" class="py-10 text-center">
                    <link-button
                            class="text-sky"
                            @click="restoreSubscription()"
                    >
                        {{ $t('settings.subscription.redeem') }}
                    </link-button>
                </div>

                <f-divider/>

                <div class="py-10 text-center">
                    <link-button
                            class="text-sky"
                            @click="$refs.privacyPolicyModal.open()"
                    >
                        {{ $t('privacy.policy') }}
                    </link-button>

                    <div class="mt-2 text-xs text-backdrop-200">{{ version }} ({{ contentVersion }})</div>
                </div>

                <f-divider/>

                <div class="py-10 text-center">
                    <link-button
                            v-if="!idVisible"
                            class="text-sky"
                            @click="idVisible = true"
                    >
                        {{ $t('settings.show.id') }}
                    </link-button>

                    <template v-else>
                        <p class="ext-sm">{{ $t('settings.user.id.title') }}</p>
                        <div class="mt-4 text-sm">{{ userId }}</div>
                    </template>
                </div>
            </f-app-body>
        </f-app-screen>

        <privacy-policy-modal ref="privacyPolicyModal"/>
        <profile-modal ref="profileModal"/>
        <confirm-sign-out-dialog ref="confirmSignOutDialog"/>
    </f-fullscreen-modal>
</template>

<script>
import LocalesService from '@/services/LocalesService'
import HapticsService from '@/services/HapticsService'
import FacebookButton from '@/components/FacebookButton.vue'
import LangItem from '@/components/LangItem.vue'
import Toggle from '@/components/Toggle.vue'
import ProfileModal from '@/modules/settings/ProfileModal.vue'
import PrivacyPolicyModal from '@/modules/settings/PrivacyPolicyModal.vue'
import LinkButton from '@/components/LinkButton.vue'
import Settings from '@/store/Settings'
import User from '@/store/User'
import Facebook from '@/services/Facebook'
import Analytics, { SCREEN_SETTINGS } from '/src/services/Analytics'
import ConfirmSignOutDialog from '/src/modules/settings/ConfirmSignOutModal.vue'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import SubService, { ACCESS_PRO, ENTITLEMENT_PRO } from '/src/services/SubService'
import AppleButton from '/src/components/AppleButton.vue'
import AuthService from '@/services/AuthService'
import AuthUserStateService from '@/services/AuthUserStateService'
import StorageService from '@/services/StorageService'
import AppService from '@/services/AppService'

export default {
  name: 'SettingsModal',

  components: {
    ConfirmSignOutDialog,
    LinkButton,
    PrivacyPolicyModal,
    ProfileModal,
    Toggle,
    LangItem,
    FacebookButton,
    AppleButton
  },

  data () {
    return {
      authenticating: false,
      version: null,
      contentVersion: null,
      idVisible: false,
      appInstanceId: null
    }
  },

  computed: {
    plan: function () {
      return SubService.state.plan
    },
    isEntitled: function () {
      return this.plan === ACCESS_PRO
    },
    isLifetime: function () {
      return this.isEntitled && SubService.isLifetime()
    },
    languages: function () {
      return LocalesService.languages.available
    },
    sound: function () {
      return Settings.getSound()
    },
    vibration: function () {
      return Settings.getVibration()
    },
    language: function () {
      return this.$i18n.locale
    },
    user: function () {
      return User.getUser()
    },
    isAuth: function () {
      return Boolean(this.user)
    },
    isFacebookAuth: function () {
      return Facebook.store().isAuth
    },
    userId: function () {
      return this.appInstanceId
    },
    isIOS: function () {
      return Capacitor.getPlatform() === 'ios'
    },
    currentLanguage: function () {
      return this.$i18n.locale
    }
  },

  methods: {
    async open () {
      SubService.refreshCustomerInfo()
      const { version } = await StorageService.get('meta', {})
      this.contentVersion = version
      await this.$refs.modal.open()
      Analytics.setScreenName(SCREEN_SETTINGS)
    },
    close () {
      this.idVisible = false
      this.$refs.modal.close()
    },
    showProfile () {
      this.$refs.profileModal.open()
    },
    async setUserLanguage (code) {
      HapticsService.impact()
      await LocalesService.setAppLanguage(code)
      await Settings.setLanguage(code)
    },
    toggleSound () {
      HapticsService.impact()
      Settings.toggleSound()
    },
    toggleVibration () {
      HapticsService.impact()
      Settings.toggleVibration()
    },
    async restoreSubscription () {
      this.restoring = this.$toasted.show(
        this.$t('settings.subscription.redeeming.notification'),
        {
          duration: 0,
          position: 'top-center'
        })

      try {
        const customerInfo = await SubService.restore()

        const message = customerInfo?.entitlements && Object.keys(customerInfo.entitlements.active).includes(ENTITLEMENT_PRO)
          ? this.$t('settings.subscription.redeeming.success')
          : this.$t('settings.subscription.redeeming.not.found')

        this.restoring
          .text(message)
          .goAway(3000)
      } catch (e) {
        this.restoring
          .text(this.$t('settings.subscription.redeeming.error'))
          .goAway(3000)
      }
    },
    async cancelSubscription () {
      const url = await SubService.getManagementUrl()
      // console.log('Uninstall url %s', url)

      if (url) {
        window.open(url)
      }
    },
    async signOut () {
      if (AuthUserStateService.isDirty()) {
        await this.$refs.confirmSignOutDialog.open()
          .onOk(async () => {
            await Facebook.logout()
            await AuthService.logout()
          })
      } else {
        await Facebook.logout()
        await AuthService.logout()
      }
    }
  },

  async created () {
    if (Capacitor.isNativePlatform()) {
      const { version, build } = await App.getInfo()
      this.version = `${version} (${build})`
      this.appInstanceId = AppService.getAppId()
    }
  }
}
</script>
