<template>
    <div
            class="rounded-lg bg-x-900"
            :class="{ 'shadow-primary': !flat }"
    >
        <slot/>
    </div>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    flat: Boolean
  }
}
</script>

<style scoped>

</style>
