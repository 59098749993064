import ConfigService from '@/services/ConfigService'
import axios from 'axios'
const DEFAULT_DEVICE = 'universal'
export const DEFAULT_BRANCH = 'default'
const DEFAULT_VERSION = '0'

async function downloadFile(url, progressCallback) {
  const axiosInstance = axios.create()

  const response = await axiosInstance.request({
    method: 'GET',
    url: url,
    responseType: 'arraybuffer', // Directly get the response as an ArrayBuffer
    onDownloadProgress: (progressEvent) => {
      const fraction = progressEvent.loaded / progressEvent.total
      progressCallback(fraction * 100)
    }
  })

  return response.data; // This will be an ArrayBuffer
}

export default {
  async fetchContent (version = null, branch = null, config = {}) {
    // console.log(`Fetching content... xxx ${version} ${branch}`)
    const baseUrl = await ConfigService.getString('content_url')
    const v = version || DEFAULT_VERSION
    const b = !branch || branch === DEFAULT_BRANCH ? '' : `-${branch}`
    const file = `sg${v}${b}-${DEFAULT_DEVICE}.tar.gz`
    const url = `${baseUrl}/${file}`

    const {
      onDownloadProgress
    } = config

    return downloadFile(url, onDownloadProgress)
  }
}
