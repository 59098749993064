<template>
    <div class="relative rounded-full h-2 bg-black bg-opacity-20 overflow-hidden" >
        <div :style="{ width: progressWidth }" class="h-full progress__bar rounded-full" :class="classes"/>
    </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number
    },
    color: {
      type: String,
      default: 'white'
    }
  },
  computed: {
    progressWidth () {
      return this.progress ? this.progress + '%' : '0'
    },
    classes () {
      return [
        `bg-${this.color}`
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.progress__bar {
  transition: width 0.25s linear;
}
</style>
