<template>
    <f-app-screen>
        <f-app-header :countdown="countdown">
            <template v-slot:left>
                <img
                        src="@/assets/icons/settings.svg?url"
                        :alt="$t('settings.title')"
                        class="cursor-pointer"
                        @click="settings()"
                        width="24"
                        height="24"
                />
            </template>

            <template v-slot:default>
                <slot name="title">
                    <span class="cursor-pointer flex flex-nowrap items-center" @click="showMoneyDisclaimer()">
                        {{ formattedBalance }}
                        <img
                                src="@/assets/icons/info-icon.svg?url"
                                class="cursor-pointer inline-block ml-2 w-4 h-4"
                                @click.stop="showMoneyDisclaimer()"
                        />
                    </span>
                </slot>
            </template>

            <template v-slot:right>
                <img
                        src="@/assets/icons/home.svg?url"
                        v-if="showHomeLink"
                        width="24"
                        height="24"
                        class="cursor-pointer"
                        @click="$router.push({ name: 'home' })"
                />
            </template>
        </f-app-header>

        <f-app-body :class="bodyClass">
            <slot/>
            <settings-modal ref="settings"/>
        </f-app-body>

        <money-disclaimer ref="moneyDisclaimer"/>
    </f-app-screen>
</template>

<script>
import { animate, steps } from 'popmotion'
import SoundService from '@/services/SoundService'
import SettingsModal from '@/modules/settings/SettingsModal.vue'
import { Scores } from '@/store'
import MoneyDisclaimer from '@/components/MoneyDisclaimer.vue'
import { formatExperience } from '@/services/Formatter'
import moneySound from '@/assets/sounds/money.mp3'
import moneyVanishSound from '@/assets/sounds/money_vanish.mp3'

export default {
  name: 'AppLayout',
  components: { MoneyDisclaimer, SettingsModal },
  props: {
    countdown: {
      type: Number,
      default: 0
    },
    bodyClass: {
      type: [Array, Object]
    }
  },
  data () {
    return {
      loading: false,
      cachedBalance: Scores.getBalance() || 0
    }
  },
  computed: {
    formattedBalance: function () {
      return formatExperience(this.cachedBalance)
    },
    balance: function () {
      return Scores.getBalance()
    },
    showHomeLink: function () {
      return this.$router.currentRoute.name !== 'home'
    }
  },
  watch: {
    balance: function (val, old) {
      const ease = steps(Math.abs(val - old), 'start')
      const increase = val > old

      animate({
        from: old,
        to: val,
        duration: increase ? 700 : 500,
        ease,
        onPlay: () => {
          if (increase) {
            SoundService.loadAndPlay('money', moneySound)
          } else {
            SoundService.loadAndPlay('moneyVanish', moneyVanishSound)
          }
        },
        onUpdate: (v) => {
          this.cachedBalance = v
        }
      })
    }
  },
  methods: {
    showMoneyDisclaimer () {
      this.$refs.moneyDisclaimer.open()
    },
    settings () {
      this.$refs.settings.open()
    }
  },
  created () {
    // Promise.all([
    //   SoundService.load('money', moneySound),
    //   SoundService.load('moneyVanish', moneyVanishSound)
    // ])
  },
  beforeDestroy () {
    // FIXME: Component is destroyed after the new one is created. So we end up removing the sound preloaded on the new component.
    // SoundService.remove('money')
    // SoundService.remove('moneyVanish')
  }
}
</script>
