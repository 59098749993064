import { FCM } from '@capacitor-community/fcm'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'
import router from '@/router'

export default {
  async init () {
    if (!Capacitor.isNativePlatform()) return

    const perm = await PushNotifications.requestPermissions();

    if (perm.receive === 'granted') {
      await PushNotifications.register()

      // Enable the auto initialization of the library
      await FCM.setAutoInit({ enabled: true })

      // Check the auto initialization status
      // const { enabled } = await FCM.isAutoInitEnabled()
      // console.log('Auto init is ' + (enabled ? 'enabled' : 'disabled'))

      // This will return APNS token on iOS
      // await PushNotifications.addListener('registration', token => {
      //   console.log('Registration token: ', token.value);
      // });

      // PushNotifications.addListener(
      //   'pushNotificationReceived',
      //   (notification) => {
      //     console.log('Push received: ' + JSON.stringify(notification))
      //   }
      // )

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        ({ notification }) => {
          const data = notification.data

          if (data?.route) {
            router.push({ name: data.route })
          }
        }
      )
    }
  },

  /**
   * Get the FCM token
   * @returns {Promise<{token: string}>}
   */
  getToken () {
    return Capacitor.isNativePlatform()
      ? FCM.getToken()
      : Promise.resolve({ token: null })
  }
}