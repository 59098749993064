import Vue from 'vue'
import StorageService from '@/services/StorageService'
import ApiService from '@/services/ApiService'
import User from '@/store/User'
import { Network } from '@capacitor/network'

const key = 'scores'

const store = Vue.observable({
  meta: {
    dirty: false
  },
  data: {
    balance: 0.00, // score
    turnover: 0.00, // revenue
    profit: 0.00,
    loss: 0.00
  }
})

const apiDataMap = {
  balance: 'score',
  turnover: 'revenue',
  profit: 'profit',
  loss: 'loss'
}

// Persist in case of data change
async function persist () {
  // console.log('Scores: Persisting...')
  store.meta.dirty = true

  try {
    await Scores.sync()
  } catch (e) {
    console.warn('Scores: Failed to sync scores', e)
  } finally {
    await StorageService.set(key, store)
  }
}

const Scores = {
  key,

  setStore: (s = {}) => {
    Object.assign(store, s)
  },

  // setData (d = {}) {
  //   Object.assign(store.data, d)
  // },

  // getData () {
  //   return store.data
  // },

  async sync () {
    // console.log('Scores: Syncing scores...')
    const status = await Network.getStatus()
    // console.log('Scores: Network status', status)
    const user = User.getUser()

    if (user && status.connected && store.meta.dirty) {
      // console.log('updating scores', store.data)
      await ApiService.updateScores(user.id, Scores.mapToApi(store.data))
      store.meta.dirty = false
      await StorageService.set(key, store)
    }
  },

  isDirty () {
    return store.meta.dirty
  },

  /**
   * Reset with API data
   * @param {Object} d API scores model
   * @return {Promise<void>}
   */
  async reset (d = {}) {
    // console.log('Scores: Resetting scores storage')

    const data = Object.assign({
      balance: 0.00, // score
      turnover: 0.00, // revenue
      profit: 0.00,
      loss: 0.00
    }, this.mapFromApi(d))

    Scores.setStore({ meta: { dirty: false }, data })

    await StorageService.set(key, store)
  },

  mapFromApi (data) {
    const r = {}
    Object.entries(apiDataMap).forEach(([key, apiKey]) => {
      if (data[apiKey] && !isNaN(data[apiKey])) {
        r[key] = Number(data[apiKey])
      }
    })

    return r
  },

  mapToApi (data) {
    const r = {}
    Object.entries(apiDataMap).forEach(([key, apiKey]) => {
      r[apiKey] = data[key]
    })

    return r
  },

  // Getters
  getBalance () {
    return store.data.balance
  },

  getTurnover () {
    return store.data.turnover
  },

  getProfit () {
    return store.data.profit
  },

  getLoss () {
    return store.data.loss
  },

  // Mutations
  addBalance: (value) => {
    const v = Number(value)
    if (v >= 0) {
      // console.log('Scores: Adding balance %s', v)
      store.data.balance += v
      store.data.turnover += v
      return persist()
    } else {
      return Scores.removeBalance(v)
    }
  },

  removeBalance: async (value) => {
    const v = Math.abs(Number(value))
    const delta = Math.min(store.data.balance, v)
    // console.log('Scores: Removing %s from current balance %s', v, store.data.balance)
    store.data.balance -= delta
    await persist()
  },

  addProfit: async (value) => {
    const v = Number(value)
    if (v < 0) {
      return Scores.addLoss(v)
    } else {
      // console.log('Scores: Adding profit %s', v)
      store.data.profit += v
      return persist()
    }
  },

  addLoss: async (value) => {
    const v = Math.abs(Number(value))
    // console.log('Scores: Adding loss %s', v)
    store.data.loss += v
    return persist()
  }
}

export default Scores
