import ApiService from '/src/services/ApiService'
import AuthUserStateService from '/src/services/AuthUserStateService'
import { User } from '/src/store'
import { Network } from '@capacitor/network'
import SubService from '/src/services/SubService'
import AppService from '@/services/AppService'

class AuthService {
  /**
   * Provide nickname for new user. Otherwise, authenticate with facebookId and email.
   *
   * @param facebookId
   * @param email
   * @param nickname
   * @returns {Promise<*>}
   */
  async authenticate ({ facebookId, email, nickname }) {
    // console.log('AuthService: authenticating...', { facebookId, email, nickname })
    const { data: res } = await ApiService.createOrGetUser({ facebookId, email, nickname })

    if (!res.user) {
      console.error('Unable to authenticate or create a user', { facebookId, email, nickname })
      throw new Error('Unable to authenticate user')
    }

    // Store authenticated user data
    await User.setUser(res.user)

    // Sync user progress
    await AuthUserStateService.sync(res.user)

    const user = User.getUser()

    SubService.logIn({
      id: user?.id,
      email: user?.email,
      displayName: user?.nickname
    })

    return res.user
  }

  /**
   *
   * @param {Object} data
   * @param {string} data.email
   * @param {string} data.givenName
   * @param {string} data.familyName
   * @param {string} data.authorizationCode
   * @param {string} data.identityToken
   * @param {string} data.user
   * @returns {Promise<void>}
   */
  async authenticateWithApple (data) {
    const { data: res } = await ApiService.createOrGetUserWithApple(data)

    if (!res.user) {
      console.error('Unable to authenticate or create a user', {
        user: data.user,
        email: data.email
      })

      throw new Error('Unable to authenticate user')
    }

    // Store authenticated user data
    await User.setUser(res.user)

    // Sync user progress
    await AuthUserStateService.sync(res.user)

    const user = User.getUser()

    SubService.logIn({
      id: user?.id,
      email: user?.email,
      displayName: user?.nickname
    })

    return res.user
  }

  async refreshUser () {
    const status = await Network.getStatus()
    const u = User.getUser()

    if (status.connected && u?.id) {
      const { data } = await ApiService.getUser(u.id)
      await User.setUser(data.user)
    }
  }

  async logout () {
    await User.reset()
    await Promise.all([
      SubService.logOut(),
      AuthUserStateService.reset(),
      AppService.resetAppId()
    ])
  }

  isAuth () {
    const user = User.getUser()
    return Boolean(user?.id)
  }
}

export default new AuthService()
