import { Quiz, Scores } from '/src/store'
import SubService, { ACCESS_FREE } from '/src/services/SubService'
import StrapiService from '@/services/StrapiService'
import Vue from 'vue'

export const QUIZ_LENGTH = 10

// Reactive cache for quiz stats
const store = Vue.observable({
  stats: null
})

const QuizService = {
  /**
   * Will get questions accessible to the user (or provided plan)
   *
   * @param {String|Number} [plan] Optional. Omit to get accessible questions for current user plan.
   * @returns {Promise<*>}
   */
  // getQuestions: async (plan) => {
  //   const { data: questions } = await StrapiService.getContentType('quiz-questions', {
  //     filters: {
  //       $or: [
  //         { package_id: ACCESS_FREE },
  //         { package_id: plan ?? SubService.state.plan }
  //       ]
  //     }
  //   })
  //
  //   return questions?.data ?? []
  // },

  /**
   *
   * @param {Boolean} refresh
   * @returns {Promise<*>}
   */
  async getStats(refresh = false) {
    if (!refresh && store.stats) {
      return store.stats;
    }

    const { data: stats } = await StrapiService.getContentType('quiz-questions/stats')

    Vue.set(store, 'stats', stats);

    return store.stats;
  },

  getQuizQuestions: async () => {
    // Reference array of already answered question ids
    const answered = Quiz.getAnsweredQuestions().map(q => q.question_id)

    const accessible = SubService.getAccessiblePackages()

    // Get all accessible questions and shuffle
    const { data: questions } = await StrapiService.searchContentType(
      'quiz-questions/random',
      {
        filters: {
          package_id: {
            $in: accessible
          },
        }
      },
      {
        answered
      })

    return questions ?? []
  },

  /**
   * Quiz answers are only stored locally
   *
   * @param {Number} questionId
   * @param {Boolean} correct
   * @returns {Promise<void>}
   */
  handleAnswer: ({ questionId, correct }) => {
    return Quiz.addAnsweredQuestion(questionId, correct)
  },

  addBalance: (value) => {
    return Scores.addBalance(value)
  },

  /**
   * Returns array of question ids that have been answered
   * @returns {*[]}
   */
  getAnsweredQuestions: () => {
    return Quiz.getAnsweredQuestions().map(q => q.question_id)
  },

  /**
   * Shorthand for resolving question packages for answered questions
   * @returns {Promise<HttpResponse>}
   */
  resolveQuestionPackages () {
    return StrapiService.getContentType('quiz-questions', {
      fields: [
        'id',
        'package_id'
      ]
    })
  }
}

export default QuizService
