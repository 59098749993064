import { SignInWithApple } from '@capacitor-community/apple-sign-in';

// Client ID is set from Capacitor app config in native environment
// Redirect URI is only needed for web environment
let options = {
  // clientId: 'com.your.webservice',
  // redirectURI: 'https://www.yourfrontend.com/login',
  scopes: 'email name',
  // state: '12345',
  // nonce: 'nonce',
};

export function signInWithApple() {
  return SignInWithApple.authorize(Object.assign(options))
}