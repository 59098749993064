<template>
  <div class="loading" v-show="showing" :class="{ 'bg-black bg-opacity-20': overlay }"></div>
</template>

<script>
import { Spinner } from 'spin.js'
import 'spin.js/spin.css'

export default {
  name: 'Loading',
  props: {
    showing: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#000000'
    },
    overlay: {
      type: Boolean
    }
  },
  data () {
    return {
      spinner: null
    }
  },
  watch: {
    showing: function (val) {
      if (val) {
        this.$nextTick(() => this.spinner.spin(this.$el))
      } else {
        this.spinner.stop()
      }
    }
  },
  methods: {},
  created () {
    const opts = {
      lines: 9, // The number of lines to draw
      length: 38, // The length of each line
      width: 17, // The line thickness
      radius: 45, // The radius of the inner circle
      scale: 0.1, // Scales overall size of the spinner
      corners: 1, // Corner roundness (0..1)
      speed: 1, // Rounds per second
      rotate: 0, // The rotation offset
      animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: this.color, // CSS color or array of colors
      fadeColor: 'transparent', // CSS color or array of colors
      top: '50%', // Top position relative to parent
      left: '50%', // Left position relative to parent
      shadow: '0 0 1px transparent', // Box-shadow for the lines
      zIndex: 2000000000, // The z-index (defaults to 2e9)
      className: 'spinner', // The CSS class to assign to the spinner
      position: 'absolute' // Element positioning
    }

    this.spinner = new Spinner(opts)
  },
  mounted () {
    if (this.showing) {
      this.spinner.spin(this.$el)
    }
  }
}
</script>

<style scoped lang="scss">
.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
