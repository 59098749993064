<template>
    <portal to="modals">
        <transition name="modal" @after-leave="leave()" @after-enter="enter()">
            <div class="modal-mask" @click="close()" v-if="visible">
                <div class="modal-container" @click.stop>
                    <slot/>
                </div>
            </div>
        </transition>
    </portal>
</template>

<script>
export default {
  name: 'Modal',
  data () {
    return {
      visible: false,
      opening: null,
      closing: null
    }
  },
  methods: {
    close () {
      return new Promise(resolve => {
        this.closing = resolve
        this.visible = false
      })
    },
    open () {
      return new Promise(resolve => {
        this.opening = resolve
        this.visible = true
      })
    },
    enter () {
      return this.opening ? this.opening() : undefined
    },
    leave () {
      return this.closing ? this.closing() : undefined
    },
    onKeyDown (e) {
      if (this.value && e.keyCode === 27) {
        this.close()
      }
    }
  },
  mounted () {
    document.addEventListener('keydown', this.onKeyDown)
  },
  destroyed () {
    document.removeEventListener('keydown', this.onKeyDown)
  }
}
</script>

<style scoped lang="scss">
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  transition: opacity .3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  max-width: calc(100vw - 4rem);
  margin: 40px auto 0;
  // background-color: #fff;
  transition: all .3s ease;

  ::v-deep > *:first-child {
    box-shadow: 0 4px 16px rgba(0, 0, 0, .5);
  }
}
</style>
