<template>
    <div class="fh-card rounded-md bg-gradient-to-br from-backdrop-light to-backdrop-dark flex flex-col items-center border border-backdrop-100 shadow-bevel-outer overflow-hidden">
        <div v-if="title" class="py-1 px-3 bg-backdrop uppercase tracking-wider text-sm">{{ title }}</div>

        <div class="fh-card__body text-center w-full" :class="{ 'p-4': padded }">
            <slot/>
        </div>

        <div class="fh-card__footer bg-panel w-full rounded-b-md border-t border-backdrop-100 flex flex-row justify-center p-4"
             v-if="$slots.footer">
            <slot name="footer"></slot>
        </div>

        <div class="bg-backdrop-100 w-full" v-if="$slots.footnote">
            <slot name="footnote"></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String
    },
    padded: {
      type: Boolean
    }
  }
}
</script>
