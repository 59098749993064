<template>
    <component
            :is="tag"
            @click="emit"
            :class="classes"
            :style="{ 'font-size': size }"
            class="f-button font-bold inline-flex items-center justify-center focus:outline-none overflow-hidden no-underline hover:no-underline"
    >
        <img v-if="icon" :src="iconSrc" class="f-button__icon"/>
        <slot/>
        <f-loading :showing="loading" overlay color="#FFFFFF"/>
    </component>
</template>

<script>
const objectifyClasses = function (classes) {
  return Array.isArray(classes)
    ? Object.fromEntries(classes.map(c => [c, true]))
    : classes
}

export default {
  name: 'FButton',
  props: {
    rounded: Boolean,
    outline: Boolean,
    icon: String,
    size: String,
    loading: Boolean,
    disable: Boolean,
    buttonClasses: {
      type: Object,
      default () {
        return {}
      }
    },
    tag: {
      type: String,
      default: 'button'
    },
    padding: {
      type: [Object, Array],
      default () {
        return ['py-2', 'px-5']
      }
    }
  },
  computed: {
    iconSrc: function () {
      return new URL(`../assets/icons/${this.icon}.svg`, import.meta.url).href
    },
    classes: function () {
      return Object.assign({
        rounded: this.rounded,
        'bg-transparent border': this.outline,
        ...objectifyClasses(this.padding)
      }, this.buttonClasses)
    }
  },
  methods: {
    emit (e) {
      if (this.disable) return
      this.$emit('click', e)
    }
  }
}
</script>

<style scoped lang="scss">
.f-button {
  // padding: .5em 1.25em;
  font-size: 14px;

  &__icon {
    margin-right: .5em;
    display: block;
    color: black;
    width: 1.4em;
    height: 1.4em;
  }
}
</style>
