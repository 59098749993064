<template>
    <f-modal ref="modal">
        <div class="rounded-xl" :class="contentClasses">
            <div class="p-6 text-center">
                <div v-if="title" class="font-medium" v-html="title"></div>
                <div v-if="message" class="text-xs mt-4">{{ message }}</div>
            </div>

            <div class="flex flex-row border-t border-white h-12">
                <f-button
                        v-if="okLabel"
                        @click="ok()"
                        class="flex-grow"
                        :class="{'w-1/2': Boolean(cancelLabel)}"
                >{{ okLabel }}</f-button>
                <div
                        v-if="cancelLabel && okLabel"
                        class="border-r border-white"
                ></div>
                <f-button
                        v-if="cancelLabel"
                        @click="cancel()"
                        class="flex-grow"
                        :class="{'w-1/2': Boolean(okLabel)}"
                >{{ cancelLabel }}</f-button>
            </div>
        </div>
    </f-modal>
</template>

<script>
export default {
  name: 'FDialog',
  props: {
    title: {
      type: String
    },
    message: {
      type: String
    },
    okLabel: {
      type: String
    },
    cancelLabel: {
      type: String
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    contentClasses: {
      type: [Object, Array],
      default: function () {
        return ['text-white', 'bg-violet', 'bg-opacity-80']
      }
    }
  },
  data () {
    return {
      okCallback: null,
      cancelCallback: null,
      response: {
        onOk: this.onOk,
        onCancel: this.onCancel
      }
    }
  },
  methods: {
    async ok () {
      if (this.okCallback) await this.okCallback()
      if (this.autoClose) await this.close()
    },
    async cancel () {
      if (this.cancelCallback) await this.cancelCallback()
      if (this.autoClose) await this.close()
    },
    open () {
      this.$refs.modal.open()
      return this.response
    },
    close () {
      return this.$refs.modal?.close()
    },
    onOk (callback) {
      this.okCallback = callback
      return this.response
    },
    onCancel (callback) {
      this.cancelCallback = callback
      return this.response
    }
  }
}
</script>
