<template>
    <div
            class="keen-slider__slide flex flex-col text-center px-8 pt-8 overflow-y-auto"
    >
        <div class="mb-8 text-center" style="flex-shrink: 1; min-height: 0;">
            <img
                    :src="src2x"
                    :srcset="`${src2x} 2x, ${src3x} 3x`"
                    class="max-h-full max-w-full object-contain mx-auto"
            />
        </div>

        <div>
            <h2 v-html="$t(`tutorial2.screen${index}.title`)" class="font-normal mb-4 text-x-50"></h2>
            <p class="text-x-100 text-sm leading-normal">{{ $t(`tutorial2.screen${index}.description`) }}</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'TutorialSlide',
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    src2x: function () {
      return new URL(`/src/assets/tutorials/tutorial-screen-${this.index}@2x.png`, import.meta.url).href
    },
    src3x: function () {
      return new URL(`/src/assets/tutorials/tutorial-screen-${this.index}@3x.png`, import.meta.url).href
    },
  }
}
</script>

<style scoped>

</style>
