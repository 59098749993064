<template>
    <button
            class="px-14 py-2 ease-in-out focus:outline-none rounded text-white font-bold relative overflow-hidden flex justify-center items-center"
            style="min-width: 180px"
            :class="{ 'bg-facebook': true, 'text-opacity-10': busy}"
            :disabled="busy"
            @click="startSignInWithFacebook()"
    >
        <img
                src="@/assets/icons/facebook-circular-logo.svg?url"
                class="block w-4 h-4 mr-3"
        />
        <span><slot/></span>
        <f-loading :showing="busy" color="#FFFFFF" overlay/>
    </button>
</template>

<script>
import Analytics, { SCREEN_FACEBOOK_LOGIN } from '@/services/Analytics'
import Facebook from '@/services/Facebook'
import AuthService from '@/services/AuthService'
import { User } from '@/store'

export default {
  name: 'FacebookButton',
  data () {
    return {
      busy: false
    }
  },
  methods: {
    async startSignInWithFacebook () {
      Analytics.setScreenName(SCREEN_FACEBOOK_LOGIN)
      // console.log('FacebookSignInMixin: signing up...')
      try {
        this.busy = true

        await Facebook.login()

        const { email, id: facebookId, name: nickname } = await Facebook.getProfile()

        if (AuthService.isAuth() && User.getUser().facebook_id !== facebookId) {
          // console.log('FacebookSignInMixin: Authenticated already with different facebook account. Logging out...')
          await AuthService.logout()
        }

        await AuthService.authenticate({ facebookId, email, nickname })
        // console.log('FacebookSignInMixin: authenticated with ForexHero')
      } catch (e) {
        console.warn('Facebook error', e)
        // Log out from facebook if FH authentication failed so user can retry
        if (Facebook.store().isAuth) {
          await Facebook.logout()
        }
      } finally {
        // console.log('FacebookSignInMixin: finally')
        this.busy = false
      }
    }
  }
}
</script>
