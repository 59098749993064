<template>
    <f-fullscreen-modal ref="modal">
        <f-app-screen>
            <f-modal-header @close="close()">{{ $t('profile') }}</f-modal-header>

            <f-app-body>
                <div class="p-10 text-center">
                    <div class="text-lg font-bold mb-4">{{ $t('profile.deleted.title') }}</div>

                    <div class="flex justify-center mt-8">
                        <link-button @click="$refs.modal.close()">{{ $t('back.to.settings') }}</link-button>
                    </div>
                </div>
            </f-app-body>
        </f-app-screen>
    </f-fullscreen-modal>
</template>

<script>
import LinkButton from '/src/components/LinkButton.vue'

export default {
  name: 'ProfileDeletedModal',
  components: { LinkButton },
  methods: {
    open () {
      return this.$refs.modal.open()
    },
    close () {
      return this.$refs.modal.close()
    }
  }
}
</script>
