<template>
    <f-dialog
            ref="confirmSignOut"
            title="Unsaved data"
            message="Your progress will be lost after signing out. Restore your internet connection to save your progress before signing out."
            ok-label="Sign out"
            cancel-label="Stay"
    />
</template>

<script>

export default {
  name: 'confirmSignOutDialog',
  methods: {
    open () {
      return this.$refs.confirmSignOut.open()
    }
  }
}
</script>
