import { Network } from '@capacitor/network'
import ApiService from '@/services/ApiService'
import Vue from 'vue'
import Crashlytics from '@/services/Crashlytics'
import { cloneDeep } from 'lodash'

const store = Vue.observable({
  config: {}
})

let networkListener = null;

function updateConfig (config) {
  if (typeof config === 'object' || config !== null) {
    store.config = Object.assign({}, store.config, config)
  }
}

export default {
  initializeConfig: async function ({ connectionTimout } = {}) {
    const { connected } = await Network.getStatus()

    // Initialize default offline config
    const isDevelopment = import.meta.env.MODE === 'development'
    let defaultConfig = {}

    if (isDevelopment) {
      // Import development-specific default config
      defaultConfig = await import('@/remote_config_local.json').then(m => m.default)
    } else {
      // Import production default config
      defaultConfig = await import('@/remote_config_defaults.json').then(m => m.default)
    }

    updateConfig(defaultConfig)

    networkListener?.remove()

    // Check online config
    if (connected) {
      try {
        const { data } = await ApiService.getRemoteConfig({ connectionTimout })
        updateConfig(data)
      } catch (e) {
        console.warn('Failed to fetch and activate remote config.', e)
        const { connected, connectionType } = await Network.getStatus()
        await Promise.all([
          Crashlytics.setContext({ key: 'connectionType', value: connectionType, type: 'string' }),
          Crashlytics.setContext({ key: 'connected', value: connected, type: 'boolean' })
        ])
        Crashlytics.recordExceptionWithStacktrace('Failed to fetch and activate remote config.', e)
      }
    } else {
      networkListener = await Network.addListener('networkStatusChange', () => this.initializeConfig())
    }
  },

  getBoolean: async (key) => {
    const value = store.config[key]

    if (typeof value === 'string') {
      return value.toLowerCase() === 'true'
    }

    return Boolean(value)
  },

  getNumber: async (key) => {
    const value = store.config[key]

    return Number(value)
  },

  getString: async (key) => {
    const value = store.config[key]

    return String(value)
  },

  getJson: async (key) => {
    const value = store.config[key]

    if (typeof value === 'string') {
      try {
        return JSON.parse(value)
      } catch (e) {
        console.warn('Failed to parse JSON', e)
        Crashlytics.recordExceptionWithStacktrace(`Failed to parse JSON from config key: ${key}`, e)
        return null
      }
    }

    if (typeof value === 'object') {
      return value
    }

    return null
  }
}
