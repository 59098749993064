<template>
  <div class="flex-grow overflow-auto">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'AppBody'
}
</script>

<style scoped>

</style>
