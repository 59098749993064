<template>
    <portal to="fullscreen-modals">
        <transition name="modal" @after-leave="leave()" @after-enter="enter()" :duration="500">
            <div v-if="modalVisible">
                <div class="modal-backdrop fixed z-40 top-0 left-0 right-0 bottom-0 w-screen h-screen bg-black bg-opacity-80"/>
                <div class="modal-container fixed z-50 top-0 left-0 right-0 bottom-0 w-screen h-screen overflow-y-auto">
                    <slot/>
                </div>
            </div>
        </transition>
    </portal>
</template>

<script>
export default {
  name: 'FullscreenModal',
  data () {
    return {
      // visible: false,
      opening: null,
      closing: null,
      modalVisible: false
    }
  },
  watch: {
    // visible (val) {
    //   this.$nextTick(() => {
    //     this.modalVisible = val
    //   })
    // }
  },
  methods: {
    close () {
      return new Promise(resolve => {
        this.closing = resolve
        this.modalVisible = false
      })
    },
    open () {
      return new Promise(resolve => {
        console.log('fullscreen modal opening')
        this.opening = resolve
        this.modalVisible = true
      })
    },
    enter () {
      return this.opening ? this.opening() : undefined
    },
    leave () {
      return this.closing ? this.closing() : undefined
    }
  },
  mounted () {
    document.addEventListener('keydown', (e) => {
      if (this.value && e.keyCode === 27) {
        this.close()
      }
    })
  }
}
</script>

<style scoped lang="scss">
.modal-backdrop {
  will-change: opacity;
}

.modal-container {
  will-change: transform, opacity;
}

.modal-enter-active .modal-backdrop,
.modal-leave-active .modal-backdrop {
  transition: opacity .25s;
}

.modal-leave-active .modal-backdrop {
  transition-delay: .25s;
}

.modal-enter .modal-backdrop,
.modal-leave-to .modal-backdrop {
  opacity: 0;
}

.modal-enter-to .modal-backdrop {
  opacity: 1;
}

.modal-enter-active .modal-container {
  animation: bounce-in .5s;
  animation-fill-mode: forwards;
}

.modal-leave-active .modal-container {
  animation: bounce-out .5s;
  animation-fill-mode: forwards;
}

@keyframes bounce-in {
  0% {
    transform: translateY(100%) scale(1);
    opacity: 0.1;
  }
  50% {
    opacity: 1;
    transform: translateY(0) scale(1.1);
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

@keyframes bounce-out {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(0) scale(1.1);
  }
  100% {
    transform: translateY(100%) scale(1);
    opacity: 1;
  }
}
</style>
