import Vue from 'vue'
import StorageService from '@/services/StorageService'
import { Network } from '@capacitor/network'
import ApiService from '@/services/ApiService'
import User from '@/store/User'

const key = 'game'

const store = Vue.observable({
  meta: {
    dirty: false
  },
  data: {
    // Answered game questions
    questions: []
  }
})

function parseLegacyData (data = {}) {
  data.questions?.forEach(q => {
    q.game_question_id = Number(q.game_question_id)

    // true, 1, '1' as true
    q.correct = q.correct === true || q.correct === 1 || q.correct === '1'
  })

  return data
}

async function persist () {
  store.meta.dirty = true

  try {
    await Game.sync()
  } catch (e) {
    console.warn('Game: Failed to sync game progress', e)
  } finally {
    await StorageService.set(key, store)
  }
}

const Game = {
  key,

  setStore: (s = {}) => {
    if (s.data) {
      s.data = parseLegacyData(s.data)
    }

    Object.assign(store, s)
  },

  isDirty () {
    return store.meta.dirty
  },

  reset: async (d = {}) => {
    const data = Object.assign(
      { questions: [] },
      parseLegacyData(d)
    )
    Game.setStore({ meta: { dirty: false }, data })
    await StorageService.set(key, store)
  },

  sync: async () => {
    const { connected } = await Network.getStatus()

    if (connected && store.meta.dirty) {
      const user = User.getUser()

      if (user) {
        await ApiService.updateSeenQuestions(user.id, store.data.questions)
        store.meta.dirty = false

        // Store dirty state change
        await StorageService.set(key, store)
      }
    }
  },

  getAnsweredQuestions: () => {
    return store.data.questions
  },

  /**
   *
   * @param {Number} questionId
   * @param {boolean} correct
   * @returns {Promise<void>}
   */
  addAnsweredQuestion: async (questionId, correct) => {
    const q = store.data.questions.find(q => Number(q.game_question_id) === Number(questionId))

    // convert legacy '1' and '0' string to boolean
    store.data.questions.forEach(q => {
      if (typeof q.correct === 'string') {
        q.correct = q.correct === '1'
      }
    })

    if (q) {
      q.correct = Boolean(correct)
    } else {
      store.data.questions.push({
        game_question_id: Number(questionId),
        correct: Boolean(correct)
      })
    }

    await persist()
  }
}

export default Game
